import React, { useEffect, useState } from 'react';
import Header from 'systems/Header';
import {
  Container,
  ContentCollapse,
  ContentCollapseArrowDown,
  ContentCollapseContainer,
  ContentCollapseQ,
  ContentCollapseTypo,
  ContentCollapseTypoContainer,
  ContentContainer,
  ContentPanel,
  ContentPanelTypo,
  Root,
  SearchBarButton,
  SearchBarContainer,
  SearchBarInput,
  SearchBarSelect,
} from './styled';
import SubBanner from 'systems/SubBanner';
import NoticeNavigator from 'systems/NoticeNavigator';
import { commonAxios } from 'api/commonAxios';
import arrowBottom from 'assets/images/arrow_bottom.png';
import qImg from 'assets/images/Q.png';
import MobileHeader from 'systems/Header/Mobile';
import MobileSubBanner from 'systems/SubBanner/Mobile';
import MobileNoticeNavigator from 'systems/NoticeNavigator/Mobile';
import MobileNoticeSearchBar from 'systems/NoticeSearchBar/Mobile';
import MobileFooter from 'systems/Footer/Mobile';

type NoticeType = {
  title: string;
  content: string;
  created_at: string;
};

const MobileQna = () => {
  const [dataList, setDataList] = useState<NoticeType[]>();

  useEffect(() => {
    commonAxios({ url: 'faqs/', method: 'GET' }).then((res) => {
      setDataList(res.data);
    });
  }, []);

  return (
    <Root>
      <MobileHeader />
      <MobileSubBanner label='공지사항' imgType='3' />
      <MobileNoticeNavigator type='2' />
      <Container>
        <MobileNoticeSearchBar />
        <ContentContainer>
          <ContentCollapse ghost bordered>
            {dataList?.map((value, index) => (
              <ContentPanel
                showArrow={false}
                header={
                  <ContentCollapseContainer>
                    <ContentCollapseTypoContainer>
                      <ContentCollapseQ src={qImg} />
                      <ContentCollapseTypo>{value.title}</ContentCollapseTypo>
                    </ContentCollapseTypoContainer>
                    <ContentCollapseArrowDown src={arrowBottom} />
                  </ContentCollapseContainer>
                }
                key={`${index}`}
              >
                <ContentPanelTypo>{value.content}</ContentPanelTypo>
              </ContentPanel>
            ))}
          </ContentCollapse>
        </ContentContainer>
      </Container>
      <MobileFooter />
    </Root>
  );
};

export default MobileQna;
