import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Container,
  InfoButton,
  InfoButtonContainer,
  InfoButtonTypo,
  InfoContainer,
  InfoInnerContainer,
  LogoContainer,
  LogoImg,
  LogoTypo,
  MenuContainer,
  MenuTypo,
  MenuTypoWrapper,
  Root,
} from './styled';
import logo from 'assets/images/logo.png';
import { commonAxios } from 'api/commonAxios';
import logoTypo from 'assets/images/logo_typo.png';

const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [userData, setUserData] = useState<any>();
  const onClickMenu =
    (
      type:
        | 'LOGO'
        | 'LOGIN'
        | 'INTRO'
        | 'JOIN'
        | 'MYPAGE'
        | 'QNA'
        | 'NOTICE'
        | 'LOAN'
        | 'BUSINESS'
        | 'LOGOUT'
    ) =>
    () => {
      if (type === 'LOGO') {
        navigate('/');
        window.scrollTo({ top: 0, behavior: 'smooth' });
        return;
      }
      if (type === 'LOGIN') {
        navigate('/login');
        return;
      }
      if (type === 'INTRO') {
        navigate('/intro');
        return;
      }
      if (type === 'JOIN') {
        navigate('/join');
        return;
      }
      if (type === 'MYPAGE') {
        navigate('/mypage');
        return;
      }
      if (type === 'NOTICE') {
        navigate('/notice');
        return;
      }
      if (type === 'LOAN') {
        navigate('/loan');
        return;
      }
      if (type === 'BUSINESS') {
        navigate('/business');
        return;
      }
      if (type === 'LOGOUT') {
        localStorage.removeItem('access_token');
        window.location.reload();
        return;
      }
      navigate('/qna');
      return;
    };
  const pathname = location.pathname.split('/')[1];

  useEffect(() => {
    let accessToken = localStorage.getItem('access_token');
    if (accessToken) {
      commonAxios({
        url: 'auth/profile',
        method: 'GET',
        Authorization: `Bearer ${accessToken}`,
      }).then((res) => {
        if (res.data?.user_info) {
          setUserData(res.data.user_info);
        }
      });
    }
  }, []);

  return (
    <Root>
      <InfoContainer>
        <InfoInnerContainer>
          {userData ? (
            <InfoButtonContainer>
              <InfoButton onClick={onClickMenu('LOGOUT')}>
                <InfoButtonTypo>로그아웃</InfoButtonTypo>
              </InfoButton>
              <InfoButton onClick={onClickMenu('MYPAGE')}>
                <InfoButtonTypo>마이페이지</InfoButtonTypo>
              </InfoButton>
              <InfoButton onClick={onClickMenu('NOTICE')}>
                <InfoButtonTypo>문의하기</InfoButtonTypo>
              </InfoButton>
            </InfoButtonContainer>
          ) : (
            <InfoButtonContainer>
              <InfoButton onClick={onClickMenu('LOGIN')}>
                <InfoButtonTypo>로그인</InfoButtonTypo>
              </InfoButton>
              <InfoButton onClick={onClickMenu('JOIN')}>
                <InfoButtonTypo>회원가입</InfoButtonTypo>
              </InfoButton>
              <InfoButton onClick={onClickMenu('NOTICE')}>
                <InfoButtonTypo>문의하기</InfoButtonTypo>
              </InfoButton>
            </InfoButtonContainer>
          )}
        </InfoInnerContainer>
      </InfoContainer>
      <Container>
        <LogoContainer onClick={onClickMenu('LOGO')}>
          <LogoImg src={logo} />
          <LogoTypo src={logoTypo} />
        </LogoContainer>
        <MenuContainer>
          <MenuTypoWrapper onClick={onClickMenu('INTRO')}>
            <MenuTypo isPointed={pathname === 'intro'}>
              GG레버리지 소개
            </MenuTypo>
          </MenuTypoWrapper>
          <MenuTypoWrapper onClick={onClickMenu('LOAN')}>
            <MenuTypo isPointed={pathname === 'loan'}>대여금</MenuTypo>
          </MenuTypoWrapper>
          <MenuTypoWrapper onClick={onClickMenu('BUSINESS')}>
            <MenuTypo isPointed={pathname === 'business'}>비지니스</MenuTypo>
          </MenuTypoWrapper>
        </MenuContainer>
      </Container>
    </Root>
  );
};

export default Header;
