import React, { useEffect, useState } from 'react';
import {
  Container,
  Root,
  SearchBarButton,
  SearchBarContainer,
  SearchBarInput,
  SearchBarSelect,
  TableContainer,
  TableContentContainer,
  TableContentTypo,
  TableHeaderContainer,
  TableHeaderTypo,
} from './styled';
import NoticeNavigator from 'systems/NoticeNavigator';
import { commonAxios } from 'api/commonAxios';
import moment from 'moment';
import MobileHeader from 'systems/Header/Mobile';
import MobileSubBanner from 'systems/SubBanner/Mobile';
import MobileNoticeNavigator from 'systems/NoticeNavigator/Mobile';
import MobileNoticeSearchBar from 'systems/NoticeSearchBar/Mobile';
import MobileFooter from 'systems/Footer/Mobile';

type NoticeType = {
  id: number;
  title: string;
  content: string;
  created_at: string;
  viewed_count: number;
};

const MobileNotice = () => {
  const [dataList, setDataList] = useState<NoticeType[]>();

  useEffect(() => {
    commonAxios({ url: 'notices/', method: 'GET' }).then((res) => {
      setDataList(res.data);
    });
  }, []);

  const onClickTable = (id: number) => () => {
    window.location.href = `/notice/details/${id}`;
  };

  return (
    <Root>
      <MobileHeader />
      <MobileSubBanner label='공지사항' imgType='3' />
      <MobileNoticeNavigator />
      <Container>
        <MobileNoticeSearchBar />
        <TableContainer>
          <TableHeaderContainer></TableHeaderContainer>
          {dataList?.map((value, index) => (
            <TableContentContainer
              onClick={onClickTable(value.id)}
              key={`table_content_container_${index}`}
            >
              <TableHeaderTypo>{value.title}</TableHeaderTypo>
              <TableContentTypo>
                {value.id} | 조회수 {value.viewed_count} | 작성일{' '}
                {moment(value.created_at).format('YYYY.MM.DD')}
              </TableContentTypo>
            </TableContentContainer>
          ))}
        </TableContainer>
      </Container>
      <MobileFooter />
    </Root>
  );
};

export default MobileNotice;
