import { message } from 'antd';
import { commonAxios } from 'api/commonAxios';
import { bankList } from 'assets/constants/bank';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import MobileFooter from 'systems/Footer/Mobile';
import Header from 'systems/Header';
import MobileHeader from 'systems/Header/Mobile';
import SubBanner from 'systems/SubBanner';
import MobileSubBanner from 'systems/SubBanner/Mobile';
import signImg from 'assets/images/sign.png';
import {
  Container,
  ContentAccountSelect,
  ContentAmountContainer,
  ContentAmountInput,
  ContentContainer,
  ContentDateInput,
  ContentItemCaptionTypo,
  ContentItemContainer,
  ContentItemDateTypo,
  ContentItemInput,
  ContentItemTitleTypo,
  ContentItemTypo,
  NameContainer,
  NameInput,
  NameLineContainer,
  NameLineSignImg,
  Root,
  SubmitButton,
  SubmitButtonWrapper,
  SubtitleTypo,
  TitleTypo,
} from './styled';
import emailjs from '@emailjs/browser';

let today = new Date();

const MobileMyPagePaper = () => {
  let accessToken = localStorage.getItem('access_token');

  const [name, setName] = useState<string>('');
  const [address, setAddress] = useState<string>('');
  const [number, setNumber] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [amount, setAmount] = useState<string>('');
  const [account1, setAccount1] = useState<string>('카카오뱅크');
  const [account2, setAccount2] = useState<string>('');
  const [account3, setAccount3] = useState<string>('');
  const [account4, setAccount4] = useState<string>('카카오뱅크');
  const [account5, setAccount5] = useState<string>('');
  const [account6, setAccount6] = useState<string>('');
  const [sign1, setSign1] = useState<string>('');
  const [sign2, setSign2] = useState<string>('');
  const [dueDate, setDueDate] = useState<any>();

  useEffect(() => {
    if (accessToken) {
      commonAxios({
        url: 'auth/profile',
        method: 'GET',
        Authorization: `Bearer ${accessToken}`,
      }).then((res) => {
        if (res.data?.user_info) {
          const data = res.data?.user_info;
          setName(data.name);
          setPhoneNumber(data.phone_number);
          setAddress(data.address);
          setSign1(data.name);
          const a = data.bank_account_number.split(' - ');
          if (a[1]) {
            setAccount1(a[0]);
            setAccount2(a[1]);
          }
        }
      });
    }
  }, []);

  const onClickSubmit = () => {
    if (name === '') {
      message.error('성명을 입력해주세요.');
      return;
    }
    if (address === '') {
      message.error('주소를 입력해주세요.');
      return;
    }
    if (number === '') {
      message.error('주민등록번호(사업자등록번호)를 입력해주세요.');
      return;
    }
    if (phoneNumber === '') {
      message.error('핸드폰번호를 입력해주세요.');
      return;
    }
    if (amount === '') {
      message.error('대여금액을 입력해주세요.');
      return;
    }
    if (account1 === '') {
      message.error('지정은행을 입력해주세요.');
      return;
    }
    if (account2 === '') {
      message.error('계좌번호를 입력해주세요.');
      return;
    }
    if (account3 === '') {
      message.error('예금주를 입력해주세요.');
      return;
    }
    commonAxios({
      url: 'rentals/create',
      method: 'POST',
      data: {
        username: name,
        address,
        social_security_number: number,
        phone_number: phoneNumber,
        amount,
        bank_account_type: account1,
        bank_account_number: account2,
        bank_account_owner_name: account3,
        due_date: moment(today)
          .add(1, 'years')
          .add(1, 'days')
          .format('YYYY-MM-DD'),
      },
      Authorization: `Bearer ${accessToken}`,
    }).then((res) => {
      emailjs.send(
        'service_y4rzxfq',
        'template_pyz13on',
        {
          username: name,
          address,
          social_security_number: number,
          phone_number: phoneNumber,
          amount,
          bank_account_type: account1,
          bank_account_number: account2,
          bank_account_owner_name: account3,
          due_date: moment(today)
            .add(1, 'years')
            .add(1, 'days')
            .format('YYYY-MM-DD'),
        },
        'OSuQlGLgqqynwLBqJ'
      );

      alert('승인이 완료된 후 연락드리겠습니다.');
      window.location.reload();
    });
  };

  useEffect(() => {
    if (!accessToken) {
      alert('로그인이 필요한 페이지입니다.');
      window.location.replace('/');
    }
  }, []);

  return (
    <Root>
      <MobileHeader />
      <MobileSubBanner label='마이페이지' imgType='4' />
      <Container>
        <TitleTypo>대여금 계약서</TitleTypo>
        <ContentContainer>
          <SubtitleTypo>대여인은 “갑”</SubtitleTypo>
          <ContentItemContainer>
            <ContentItemTitleTypo>성명(회사이름과 대표자)</ContentItemTitleTypo>
            <ContentItemInput
              placeholder='성명(회사이름과 대표자) 입력 '
              bordered={false}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </ContentItemContainer>
          <ContentItemContainer>
            <ContentItemTitleTypo>주소</ContentItemTitleTypo>
            <ContentItemInput
              placeholder='주소 입력 '
              bordered={false}
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
          </ContentItemContainer>
          <ContentItemContainer>
            <ContentItemTitleTypo>
              주민등록번호(사업자등록번호)
            </ContentItemTitleTypo>
            <ContentItemInput
              placeholder='주민등록번호(사업자등록번호) 입력 '
              bordered={false}
              value={number}
              onChange={(e) => setNumber(e.target.value)}
            />
          </ContentItemContainer>
          <ContentItemContainer>
            <ContentItemTitleTypo>핸드폰번호</ContentItemTitleTypo>
            <ContentItemInput
              placeholder='핸드폰번호 입력 '
              bordered={false}
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
          </ContentItemContainer>
        </ContentContainer>
        <ContentContainer>
          <SubtitleTypo>차용인은 “을”</SubtitleTypo>
          <ContentItemContainer>
            <ContentItemTitleTypo>성명(회사이름과 대표자)</ContentItemTitleTypo>
            <ContentItemInput value='GG레버리지 남태훈' bordered={false} />
          </ContentItemContainer>
          <ContentItemContainer>
            <ContentItemTitleTypo>주소</ContentItemTitleTypo>
            <ContentItemInput
              value='서울시 강남구 테헤란로 82길15 1288호(대치동, 디아이타워)'
              bordered={false}
            />
          </ContentItemContainer>
          <ContentItemContainer>
            <ContentItemTitleTypo>
              주민등록번호(사업자등록번호)
            </ContentItemTitleTypo>
            <ContentItemInput value='894-05-02368' bordered={false} />
          </ContentItemContainer>
          <ContentItemContainer>
            <ContentItemTitleTypo>핸드폰번호</ContentItemTitleTypo>
            <ContentItemInput value='010-2886-3505' bordered={false} />
          </ContentItemContainer>
        </ContentContainer>
        <ContentItemTypo style={{ marginTop: '100px', marginBottom: '50px' }}>
          “갑”과 “을”은 다음과 같이 대여금지급 및 상환에 대한 약정을 체결한다.
        </ContentItemTypo>
        <ContentContainer>
          <SubtitleTypo>제1조(금액)</SubtitleTypo>
          <ContentAmountContainer>
            <ContentItemTypo>“갑”은 “을”에게</ContentItemTypo>
            <ContentAmountInput
              placeholder='대여금액 입력'
              value={amount}
              onChange={(e: any) => setAmount(e)}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
            />
            <ContentItemTypo>
              <strong> 원</strong> 을 빌려주고 “을”은 이를 빌린다.
            </ContentItemTypo>
          </ContentAmountContainer>
        </ContentContainer>
        <ContentContainer>
          <SubtitleTypo>제2조(이자.계약기간 및 변제방법)</SubtitleTypo>
          <ContentAmountContainer>
            <ContentItemTypo>
              위 차용금(빌리는 돈)의 이자는 원금에 대하여{' '}
              <strong> 연( 24%)</strong>의 비율에 의하여 매월 말일 “갑”이
              지정하는 아래 계좌에 송금하며 차용원금을 &nbsp;
              {moment(today)
                .add(1, 'years')
                .add(1, 'days')
                .format('YYYY년 MM월 DD일')}
              까지 모두 갚기로 하며 “갑”이 지정하는 아래 계좌에 송금하여
              지급한다.
            </ContentItemTypo>
          </ContentAmountContainer>
          <ContentItemContainer style={{ marginTop: '20px' }}>
            <ContentItemTitleTypo>지정은행</ContentItemTitleTypo>
            <ContentAccountSelect
              value={account1}
              onChange={(e: any) => setAccount1(e)}
            >
              {bankList.map((value, index) => (
                <ContentAccountSelect.Option value={value}>
                  {value}
                </ContentAccountSelect.Option>
              ))}
            </ContentAccountSelect>
          </ContentItemContainer>
          <ContentItemContainer>
            <ContentItemTitleTypo>계좌번호</ContentItemTitleTypo>
            <ContentItemInput
              placeholder='계좌번호 입력 '
              bordered={false}
              value={account2}
              onChange={(e) => setAccount2(e.target.value)}
            />
          </ContentItemContainer>
          <ContentItemContainer>
            <ContentItemTitleTypo>예금주</ContentItemTitleTypo>
            <ContentItemInput
              placeholder='예금주 입력 '
              bordered={false}
              value={account3}
              onChange={(e) => setAccount3(e.target.value)}
            />
          </ContentItemContainer>
        </ContentContainer>
        <ContentContainer>
          <SubtitleTypo>제3조(대여금입금방법)</SubtitleTypo>
          <ContentAmountContainer>
            <ContentItemTypo>
              <>
                대여인“갑”은 위 대여금을 “을”이 지정하는 아래 계좌에 송금한다.
              </>
            </ContentItemTypo>
          </ContentAmountContainer>
          <ContentItemContainer style={{ marginTop: '20px' }}>
            <ContentItemTitleTypo>지정은행</ContentItemTitleTypo>
            <ContentAccountSelect
              size='large'
              value={account4}
              onChange={(e: any) => setAccount4(e)}
              disabled
            >
              {bankList.map((value, index) => (
                <ContentAccountSelect.Option value={value}>
                  {value}
                </ContentAccountSelect.Option>
              ))}
            </ContentAccountSelect>
          </ContentItemContainer>
          <ContentItemContainer>
            <ContentItemTitleTypo>계좌번호</ContentItemTitleTypo>
            <ContentItemInput
              value='3333-02-9210128'
              placeholder='계좌번호 입력 '
              bordered={false}
            />
          </ContentItemContainer>
          <ContentItemContainer>
            <ContentItemTitleTypo>예금주</ContentItemTitleTypo>
            <ContentItemInput
              value='남태훈'
              placeholder='예금주 입력 '
              bordered={false}
            />
          </ContentItemContainer>
        </ContentContainer>
        <ContentItemTypo style={{ marginTop: '40px' }}>
          대여금 계약기간은 1년으로 정한다.
        </ContentItemTypo>
        <ContentItemCaptionTypo style={{ marginTop: '10px' }}>
          ※ 단, 상호협의 하에 부득이 한 경우 6개월 이후 조기 상환 및 기일조정
          가능함
        </ContentItemCaptionTypo>
        <ContentItemDateTypo>
          {moment(today).format('YYYY년 MM월 DD일')}
        </ContentItemDateTypo>
        <NameContainer>
          <NameLineContainer>
            <ContentItemTitleTypo style={{ width: 'fit-content' }}>
              대여인”갑”
            </ContentItemTitleTypo>
            <NameInput
              value={sign1}
              onChange={(e) => setSign1(e.target.value)}
              bordered={false}
              placeholder='서명 또는 인'
            />
          </NameLineContainer>
          <NameLineContainer>
            <ContentItemTitleTypo style={{ width: 'fit-content' }}>
              차용인”을”
            </ContentItemTitleTypo>
            <NameInput
              value='남태훈'
              bordered={false}
              placeholder='서명 또는 인'
            />
            <NameLineSignImg src={signImg} />
          </NameLineContainer>
        </NameContainer>
        <SubmitButtonWrapper>
          <SubmitButton onClick={onClickSubmit} type='primary'>
            작성완료
          </SubmitButton>
        </SubmitButtonWrapper>
      </Container>
      <MobileFooter />
    </Root>
  );
};

export default MobileMyPagePaper;
