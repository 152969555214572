import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Main from 'pages/Main';
import 'antd/dist/antd.css';
import Login from 'pages/Login';
import Notice from 'pages/Notice';
import Join from 'pages/Join';
import 'default.css';
import Intro from 'pages/Intro';
import Loan from 'pages/Loan';
import Business from 'pages/Business';
import MyPage from 'pages/MyPage';
import NoticeDetails from 'pages/Notice/Details';
import MyQuestion from 'pages/MyQuestion';
import Question from 'pages/Question';
import Board from 'pages/Board';
import Qna from 'pages/Qna';
import MyPagePaper from 'pages/MyPage/Paper';
import Search from 'pages/Search';
import Terms from 'pages/Terms';
import { commonAxios } from 'api/commonAxios';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const App = () => {
  useEffect(() => {
    commonAxios({ url: 'visit', method: 'POST' });
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Main />} />
        <Route path='/login' element={<Login />} />
        <Route path='/join' element={<Join />} />
        <Route path='/qna' element={<Qna />} />
        <Route path='/question' element={<Question />} />
        <Route path='/my_question' element={<MyQuestion />} />
        <Route path='/board' element={<Board />} />
        <Route path='/search/:category/:value' element={<Search />} />
        <Route path='/notice' element={<Notice />} />
        <Route path='/notice/details/:id' element={<NoticeDetails />} />
        <Route path='/intro' element={<Intro />} />
        <Route path='/loan' element={<Loan />} />
        <Route path='/business' element={<Business />} />
        <Route path='/mypage' element={<MyPage />} />
        <Route path='/mypage/paper' element={<MyPagePaper />} />
        <Route path='/terms' element={<Terms />} />
      </Routes>
    </BrowserRouter>
  );
};

root.render(<App />);
