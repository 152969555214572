import React, { useEffect, useState } from 'react';
import Header from 'systems/Header';
import {
  BannerImg,
  Container,
  Root,
  SearchBarButton,
  SearchBarContainer,
  SearchBarInput,
  SearchBarSelect,
  TableContainer,
  TableContentContainer,
  TableContentTypo,
  TableHeaderContainer,
  TableHeaderTypo,
} from './styled';
import SubBanner from 'systems/SubBanner';
import NoticeNavigator from 'systems/NoticeNavigator';
import { commonAxios } from 'api/commonAxios';
import moment from 'moment';
import { isMobile } from 'react-device-detect';
import MobileNotice from './Mobile';
import NoticeSearchBar from 'systems/NoticeSearchBar';
import Footer from 'systems/Footer';

type NoticeType = {
  id: number;
  title: string;
  content: string;
  created_at: string;
  viewed_count: number;
};

const Notice = () => {
  const [dataList, setDataList] = useState<NoticeType[]>();

  useEffect(() => {
    commonAxios({ url: 'notices/', method: 'GET' }).then((res) => {
      setDataList(res.data);
    });
  }, []);

  const onClickTable = (id: number) => () => {
    window.location.href = `/notice/details/${id}`;
  };

  if (isMobile) {
    return <MobileNotice />;
  }

  return (
    <Root>
      <Header />
      <SubBanner label='공지사항' imgType='3' />
      <NoticeNavigator />
      <Container>
        <NoticeSearchBar />
        <TableContainer>
          <TableHeaderContainer>
            <TableHeaderTypo width={90}>NO.</TableHeaderTypo>
            <TableHeaderTypo width={677}>제목</TableHeaderTypo>
            <TableHeaderTypo width={100}>조회수</TableHeaderTypo>
            <TableHeaderTypo width={130}>등록일</TableHeaderTypo>
          </TableHeaderContainer>
          {dataList?.map((value, index) => (
            <TableContentContainer
              onClick={onClickTable(value.id)}
              key={`table_content_container_${index}`}
            >
              <TableContentTypo width={90}>{value.id}</TableContentTypo>
              <TableContentTypo
                style={{ justifyContent: 'flex-start', paddingLeft: 30 }}
                width={677}
              >
                {value.title}
              </TableContentTypo>
              <TableContentTypo width={100}>
                {value.viewed_count}
              </TableContentTypo>
              <TableContentTypo width={130}>
                {moment(value.created_at).format('YYYY.MM.DD')}
              </TableContentTypo>
            </TableContentContainer>
          ))}
        </TableContainer>
      </Container>
      <Footer />
    </Root>
  );
};

export default Notice;
