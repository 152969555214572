import React from 'react';
import { MenuTypo, MenuWrapper, Root } from './styled';

type Props = {
  type?: '1' | '2' | '3' | '4' | '5';
};

const NoticeNavigator: React.FC<Props> = ({ type = '1' }) => {
  const onClickMenu = (id: '1' | '2' | '3' | '4' | '5') => () => {
    if (id === '1') {
      window.location.href = `/notice/`;
      return;
    }
    if (id === '2') {
      window.location.href = `/qna/`;
      return;
    }
    if (id === '3') {
      window.location.href = `/question/`;
      return;
    }
    if (id === '4') {
      window.location.href = `/my_question/`;
      return;
    }
    if (id === '5') {
      window.location.href = `/board/`;
      return;
    }
    return;
  };

  return (
    <Root>
      <MenuWrapper onClick={onClickMenu('1')}>
        <MenuTypo isPointed={type === '1'}>공지사항</MenuTypo>
      </MenuWrapper>
      <MenuWrapper onClick={onClickMenu('2')}>
        <MenuTypo isPointed={type === '2'}>자주 묻는 질문</MenuTypo>
      </MenuWrapper>
      <MenuWrapper onClick={onClickMenu('3')}>
        <MenuTypo isPointed={type === '3'}>1:1 문의하기</MenuTypo>
      </MenuWrapper>
      <MenuWrapper onClick={onClickMenu('4')}>
        <MenuTypo isPointed={type === '4'}>나의 1:1 문의 내역</MenuTypo>
      </MenuWrapper>
      {/* <MenuWrapper onClick={onClickMenu('5')}>
        <MenuTypo isPointed={type === '5'}>일반게시판</MenuTypo>
      </MenuWrapper> */}
    </Root>
  );
};

export default NoticeNavigator;
