import React from 'react';
import { Container, ContentTypo, LogoImg, Root } from './styled';
import logoImg from 'assets/images/logo.png';

const Footer = () => {
  return (
    <Root>
      <Container>
        <ContentTypo>
          상호: 지지 레버리지 GG leverage <br />
          대표 : 남태훈
          <br />
          주소: 서울특별시 강남구 테헤란로 82길 15.1288호(대치동.디아이타워)
          <br />
          사업자등록번호 : 894-05-02368
          <br />
          대표전화 : 02-3391-3505
        </ContentTypo>
        <LogoImg src={logoImg} />
      </Container>
    </Root>
  );
};

export default Footer;
