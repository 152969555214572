import React, { useState } from 'react';
import {
  ContentImg,
  ContentImgWrapper,
  MenuContainer,
  MenuTypo,
  MenuTypoWrapper,
  Root,
} from './styled';
import business_content_2 from 'assets/images/mobile_business_2.png';
import business_content_3 from 'assets/images/mobile_business_3.png';
import business_content_4 from 'assets/images/mobile_business_4.png';
import business_content_5 from 'assets/images/mobile_business_5.png';
import business_content_6 from 'assets/images/mobile_business_6.png';
import MobileHeader from 'systems/Header/Mobile';
import MobileSubBanner from 'systems/SubBanner/Mobile';

const MobileBusiness = () => {
  const [select, setSelect] = useState<'2' | '3' | '4' | '5' | '6'>('2');

  const currentImage = (() => {
    if (select === '2') {
      return business_content_2;
    }
    if (select === '3') {
      return business_content_3;
    }
    if (select === '4') {
      return business_content_4;
    }
    if (select === '5') {
      return business_content_5;
    }
    if (select === '6') {
      return business_content_6;
    }
  })();

  return (
    <Root>
      <MobileHeader />
      <MobileSubBanner imgType='2' label='비지니스' />
      <MenuContainer>
        <MenuTypoWrapper onClick={() => setSelect('2')}>
          <MenuTypo isPointed={select === '2'}>회원 및 용어정리</MenuTypo>
        </MenuTypoWrapper>
        <MenuTypoWrapper onClick={() => setSelect('3')}>
          <MenuTypo isPointed={select === '3'}>후원자란?</MenuTypo>
        </MenuTypoWrapper>
        <MenuTypoWrapper onClick={() => setSelect('4')}>
          <MenuTypo isPointed={select === '4'}>멤버 가입방법</MenuTypo>
        </MenuTypoWrapper>
        <MenuTypoWrapper onClick={() => setSelect('5')}>
          <MenuTypo isPointed={select === '5'}>쉐어</MenuTypo>
        </MenuTypoWrapper>
        <MenuTypoWrapper onClick={() => setSelect('6')}>
          <MenuTypo isPointed={select === '6'}>계산지급기준 예시</MenuTypo>
        </MenuTypoWrapper>
      </MenuContainer>
      <ContentImgWrapper>
        <ContentImg src={currentImage} />
      </ContentImgWrapper>
    </Root>
  );
};

export default MobileBusiness;
