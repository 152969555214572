import { Typography } from 'antd';
import styled from 'styled-components';

export const Root = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
`;

export const BannerImg = styled.img`
  width: 1920px;
  height: 170px;
  z-index: -1;
`;

export const TitleTypo = styled(Typography)`
  font-family: Pretendard;
  font-size: 42px;
  font-weight: bold;
  color: white;
  position: absolute;
  top: 60px;
  left: 50%;
  transform: translateX(-50%);
`;
