import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Container,
  ContentDrawer,
  ContentDrawerBar,
  ContentDrawerBody,
  ContentDrawerHeaderContainer,
  ContentDrawerMenuContainer,
  LogoContainer,
  LogoImg,
  LogoTypo,
  MenuContainer,
  MenuImg,
  MenuTypo,
  MenuTypoWrapper,
  Root,
} from './styled';
import logo from 'assets/images/logo.png';
import arrowNext from 'assets/images/arrow_next.png';
import { commonAxios } from 'api/commonAxios';
import logoTypoImg from 'assets/images/logo_typo.png';

const MobileHeader = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);
  const [userData, setUserData] = useState<any>();
  const onClickMenu =
    (
      type:
        | 'LOGO'
        | 'LOGIN'
        | 'INTRO'
        | 'JOIN'
        | 'MYPAGE'
        | 'QNA'
        | 'NOTICE'
        | 'LOAN'
        | 'BUSINESS'
        | 'LOGOUT'
    ) =>
    () => {
      setOpen(false);
      if (type === 'LOGO') {
        navigate('/');
        window.scrollTo({ top: 0, behavior: 'smooth' });
        return;
      }
      if (type === 'LOGIN') {
        navigate('/login');
        return;
      }
      if (type === 'INTRO') {
        navigate('/intro');
        return;
      }
      if (type === 'JOIN') {
        navigate('/join');
        return;
      }
      if (type === 'MYPAGE') {
        navigate('/mypage');
        return;
      }
      if (type === 'NOTICE') {
        navigate('/notice');
        return;
      }
      if (type === 'LOAN') {
        navigate('/loan');
        return;
      }
      if (type === 'BUSINESS') {
        navigate('/business');
        return;
      }
      if (type === 'LOGOUT') {
        localStorage.clear();
        window.location.reload();
        return;
      }
      navigate('/qna');
      return;
    };

  useEffect(() => {
    let accessToken = localStorage.getItem('access_token');
    if (accessToken) {
      commonAxios({
        url: 'auth/profile',
        method: 'GET',
        Authorization: `Bearer ${accessToken}`,
      }).then((res) => {
        if (res.data?.user_info) {
          setUserData(res.data.user_info);
        }
      });
    }
  }, []);

  return (
    <>
      <Root>
        <Container>
          <LogoContainer>
            <LogoImg src={logo} onClick={onClickMenu('LOGO')} />
            <LogoTypo src={logoTypoImg} />
          </LogoContainer>
          <MenuContainer>
            <MenuImg onClick={() => setOpen(true)} />
          </MenuContainer>

          <ContentDrawer
            placement='right'
            headerStyle={{ display: 'none' }}
            visible={open}
            onClose={() => setOpen(false)}
          >
            <ContentDrawerHeaderContainer>
              <LogoContainer>
                <LogoImg src={logo} onClick={onClickMenu('LOGO')} />
                <LogoTypo src={logoTypoImg} />
              </LogoContainer>
              <MenuContainer>
                <img
                  src={arrowNext}
                  style={{ width: 32, height: 32 }}
                  onClick={() => setOpen(false)}
                />
              </MenuContainer>
            </ContentDrawerHeaderContainer>
            <ContentDrawerBody>
              <ContentDrawerMenuContainer>
                <MenuTypoWrapper onClick={onClickMenu('LOGO')}>
                  <MenuTypo isPointed={false}>홈</MenuTypo>
                </MenuTypoWrapper>
                <MenuTypoWrapper onClick={onClickMenu('INTRO')}>
                  <MenuTypo isPointed={false}>GG레버리지 소개</MenuTypo>
                </MenuTypoWrapper>
                <MenuTypoWrapper onClick={onClickMenu('LOAN')}>
                  <MenuTypo isPointed={false}>대여금</MenuTypo>
                </MenuTypoWrapper>
                <MenuTypoWrapper onClick={onClickMenu('BUSINESS')}>
                  <MenuTypo isPointed={false}>비지니스</MenuTypo>
                </MenuTypoWrapper>
                <ContentDrawerBar />
                {!userData ? (
                  <>
                    <MenuTypoWrapper onClick={onClickMenu('LOGIN')}>
                      <MenuTypo isPointed={false}>로그인</MenuTypo>
                    </MenuTypoWrapper>
                    <MenuTypoWrapper onClick={onClickMenu('JOIN')}>
                      <MenuTypo isPointed={false}>회원가입</MenuTypo>
                    </MenuTypoWrapper>
                  </>
                ) : (
                  <>
                    <MenuTypoWrapper onClick={onClickMenu('LOGOUT')}>
                      <MenuTypo isPointed={false}>로그아웃</MenuTypo>
                    </MenuTypoWrapper>
                    <MenuTypoWrapper onClick={onClickMenu('MYPAGE')}>
                      <MenuTypo isPointed={false}>마이페이지</MenuTypo>
                    </MenuTypoWrapper>
                  </>
                )}
                <MenuTypoWrapper onClick={onClickMenu('NOTICE')}>
                  <MenuTypo isPointed={false}>문의하기</MenuTypo>
                </MenuTypoWrapper>
              </ContentDrawerMenuContainer>
            </ContentDrawerBody>
          </ContentDrawer>
        </Container>
      </Root>
    </>
  );
};

export default MobileHeader;
