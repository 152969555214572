import { Button, Collapse, Input, Select, Typography } from 'antd';
import styled from 'styled-components';

export const Root = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-top: 56px;
  padding-bottom: 150px;
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const SearchBarContainer = styled.div`
  background: #f9f9f9;
  display: flex;
  justify-content: center;
  gap: 10px;
  padding: 30px;
  box-sizing: border-box;
  margin-top: 62px;
`;

export const SearchBarSelect = styled(Select)`
  width: 115px;
  height: 40px;
  .ant-select-selector {
    &&& {
      height: 40px;
    }
  }
`;

export const SearchBarInput = styled(Input)`
  width: 376px;
  height: 40px;
`;

export const SearchBarButton = styled(Button)`
  width: 76px;
  height: 40px;
  background: #5135a4;
  font-family: Pretendard;
  font-size: 14px;
  color: #fff;
`;

export const ContentContainer = styled.div`
  width: 315px;
  display: flex;
  flex-direction: column;
  margin-top: 80px;
`;

export const ContentCollapse = styled(Collapse)`
  &&& {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
`;

export const ContentCollapseContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ContentCollapseTypoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

export const ContentCollapseQ = styled.img`
  &&& {
    width: 15px;
    height: 20px;
  }
`;

export const ContentCollapseTypo = styled(Typography)`
  &&& {
    font-family: Pretendard;
    font-size: 16px;
    font-weight: bold;
    color: #333;
  }
`;

export const ContentCollapseArrowDown = styled.img`
  width: 19px;
  height: 7px;
`;

export const ContentPanel = styled(Collapse.Panel)`
  &&& {
    box-shadow: 2px 2px 16px rgba(119, 119, 119, 0.25);
    padding: 24px 18px;
    border-radius: 20px;
  }
`;

export const ContentPanelTypo = styled(Typography)`
  &&& {
    font-family: Pretendard;
    font-size: 14px;
    color: #333;
    margin-top: 20px;
  }
`;
export const TableContainer = styled.div`
  width: 100%;
  padding: 0 10px;
  margin-top: 70px;
`;

export const TableHeaderContainer = styled.div`
  width: 100%;
  height: 50px;
  background: #f9f9f9;
  display: flex;
  flex-direction: column;
  border-top: 1px #333 solid;
  border-bottom: 1px #e3e3e3 solid;
`;

export const TableHeaderTypo = styled(Typography)`
  font-family: Pretendard;
  font-size: 16px;
  font-weight: bold;
  color: #333;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TableContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  box-sizing: border-box;
  border-bottom: 1px #e3e3e3 solid;
  cursor: pointer;
`;

export const TableContentTypo = styled(Typography)`
  font-family: Pretendard;
  font-size: 14px;
  color: #9e9e9e;
  display: flex;
  justify-content: center;
  align-items: center;
`;
