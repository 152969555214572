import axios from 'axios';

type CommonAxiosProps = {
  url: string;
  method: 'GET' | 'POST' | 'PATCH' | 'PUT';
  params?: any;
  data?: any;
  Authorization?: any;
};

export const commonAxios = ({
  url,
  method,
  params,
  data,
  Authorization,
}: CommonAxiosProps) => {
  return axios({
    url: `https://gg-leverage-admin.herokuapp.com/api/${url}`,
    method,
    params,
    data,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization,
    },
  });
};
