import React from 'react';
import Header from 'systems/Header';
import SubBanner from 'systems/SubBanner';
import { ContentImg, Root } from './styled';
import intro_content from 'assets/images/intro_content.png';
import { isMobile } from 'react-device-detect';
import MobileIntro from './Mobile';

const Intro = () => {
  if (isMobile) {
    return <MobileIntro />;
  }

  return (
    <Root>
      <Header />
      <SubBanner imgType='2' label='GG레버러지 소개' />
      <ContentImg src={intro_content} />
    </Root>
  );
};

export default Intro;
