import React, { useEffect, useState } from 'react';
import {
  Container,
  ContentContainer,
  ContentHeaderChip,
  ContentHeaderContainer,
  ContentHeaderInfoContainer,
  ContentHeaderResponseChip,
  ContentHeaderTypo,
  ContentResponseImg,
  Root,
  SearchBarButton,
  SearchBarContainer,
  SearchBarInput,
  SearchBarSelect,
} from './styled';
import { commonAxios } from 'api/commonAxios';
import responseImg from 'assets/images/response.png';
import moment from 'moment';
import MobileHeader from 'systems/Header/Mobile';
import MobileSubBanner from 'systems/SubBanner/Mobile';
import MobileNoticeNavigator from 'systems/NoticeNavigator/Mobile';
import DaumPostcodeEmbed from 'react-daum-postcode';
import MobileNoticeSearchBar from 'systems/NoticeSearchBar/Mobile';
import { ContentHeaderRoot } from '../styled';
import MobileFooter from 'systems/Footer/Mobile';

type NoticeType = {
  title: string;
  content: string;
  created_at: string;
  answer: {
    title: string;
    content: string;
    created_at: string;
  };
};

const MobileMyQuestion = () => {
  const [dataList, setDataList] = useState<NoticeType[]>();
  let accessToken = localStorage.getItem('access_token');

  useEffect(() => {
    commonAxios({
      url: 'questions/',
      method: 'GET',
      Authorization: `Bearer ${accessToken}`,
    }).then((res) => {
      setDataList(res.data);
    });
  }, []);

  return (
    <Root>
      <MobileHeader />
      <MobileSubBanner label='공지사항' imgType='3' />
      <MobileNoticeNavigator type='4' />
      <MobileNoticeSearchBar />
      <Container>
        <ContentContainer>
          {dataList?.map((value, index) => {
            return (
              <>
                <ContentHeaderRoot>
                  <ContentHeaderContainer>
                    <ContentHeaderInfoContainer>
                      <ContentHeaderChip>질문</ContentHeaderChip>
                      <ContentHeaderTypo>{value.title}</ContentHeaderTypo>
                    </ContentHeaderInfoContainer>
                    <ContentHeaderTypo>
                      {moment(value.created_at).format('YYYY.MM.DD')}
                    </ContentHeaderTypo>
                  </ContentHeaderContainer>
                  <ContentHeaderTypo
                    style={{ marginLeft: 0, marginBottom: 10 }}
                  >
                    {value.content}
                  </ContentHeaderTypo>
                </ContentHeaderRoot>
                {value?.answer && (
                  <ContentHeaderRoot>
                    <ContentHeaderContainer>
                      <ContentHeaderInfoContainer>
                        <ContentResponseImg src={responseImg} />
                        <ContentHeaderResponseChip>
                          답변
                        </ContentHeaderResponseChip>
                        <ContentHeaderTypo>
                          {value.answer.title}
                        </ContentHeaderTypo>
                      </ContentHeaderInfoContainer>
                      <ContentHeaderTypo>
                        {moment(value.answer.created_at).format('YYYY.MM.DD')}
                      </ContentHeaderTypo>
                    </ContentHeaderContainer>

                    <ContentHeaderTypo
                      style={{ marginLeft: 30, marginBottom: 10 }}
                    >
                      {value.answer.content}
                    </ContentHeaderTypo>
                  </ContentHeaderRoot>
                )}
              </>
            );
          })}
        </ContentContainer>
      </Container>
      <MobileFooter />
    </Root>
  );
};

export default MobileMyQuestion;
