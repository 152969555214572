import {
  Button,
  DatePicker,
  Input,
  InputNumber,
  Select,
  Typography,
} from 'antd';
import styled from 'styled-components';

export const Root = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-top: 56px;
`;

export const Container = styled.div`
  width: 320px;
  display: flex;
  flex-direction: column;
  padding-top: 80px;
  padding-bottom: 150px;
`;

export const TitleTypo = styled(Typography)`
  &&& {
    height: 45px;
    font-family: Pretendard;
    font-size: 24px;
    font-weight: bold;
    color: #333;
    border-bottom: 1px #333 solid;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 50px;
`;

export const SubtitleTypo = styled(Typography)`
  &&& {
    font-family: Pretendard;
    font-size: 14px;
    font-weight: bold;
    color: #333;
  }
`;

export const ContentItemContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ContentItemTitleTypo = styled(Typography)`
  &&& {
    width: 250px;
    font-family: Pretendard;
    font-size: 14px;
    font-weight: bold;
    color: #333;
  }
`;

export const ContentItemInput = styled(Input)`
  &&& {
    width: 480px;
    border-bottom: 1px #777 solid;
  }
`;

export const ContentItemTypo = styled(Typography)`
  &&& {
    font-family: Pretendard;
    font-size: 16px;
    flex-wrap: wrap;
    color: #333;
  }
`;

export const ContentAmountContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

export const ContentAmountInput = styled(InputNumber)`
  width: 183px;
  margin-left: 14px;
  margin-right: 7px;
`;

export const ContentDateInput = styled(DatePicker)`
  width: 130px;
  margin-left: 14px;
  margin-right: 7px;
`;

export const ContentItemCaptionTypo = styled(Typography)`
  &&& {
    font-family: Pretendard;
    font-size: 14px;
    font-weight: 500;
    color: #333;
  }
`;

export const ContentItemDateTypo = styled(Typography)`
  &&& {
    width: 100%;
    font-family: Pretendard;
    font-size: 18px;
    font-weight: 500;
    color: #333;
    display: flex;
    justify-content: center;
    margin-top: 50px;
  }
`;

export const NameContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 30px;
  margin-top: 50px;
`;

export const NameLineContainer = styled.div`
  display: flex;
  gap: 60px;
  position: relative;
`;

export const NameLineSignImg = styled.img`
  width: 50px;
  height: 50px;
  position: absolute;
  top: -20px;
  right: 0;
`;

export const NameInput = styled(Input)`
  &&& {
    width: 164px;
    border-bottom: 1px #777 solid;
  }
`;

export const SubmitButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 50px;
`;

export const SubmitButton = styled(Button)`
  width: 146px;
  height: 42px;
  background: #5135a4;
`;

export const ContentAccountSelect = styled(Select)``;
