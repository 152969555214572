import { Button, Input, Select, Typography } from 'antd';
import styled from 'styled-components';

export const Root = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-top: 56px;
  padding-bottom: 150px;
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 30px;
`;

export const SearchBarContainer = styled.div`
  background: #f9f9f9;
  display: flex;
  justify-content: center;
  gap: 10px;
  padding: 30px;
  box-sizing: border-box;
  margin-top: 62px;
`;

export const SearchBarSelect = styled(Select)`
  width: 68px;
  height: 32px;
  .ant-select-selector {
    &&& {
      height: 32px;
    }
  }
`;

export const SearchBarInput = styled(Input)`
  width: 184px;
  height: 32px;
`;

export const SearchBarButton = styled(Button)`
  width: 51px;
  height: 32px;
  background: #5135a4;
  font-family: Pretendard;
  font-size: 14px;
  color: #fff;
`;

export const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-top: 1px #333 solid;
  margin-top: 70px;
`;

export const ContentHeaderRoot = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-bottom: 1px #e3e3e3 solid;
  padding: 0 24px;
  box-sizing: border-box;
`;

export const ContentHeaderContainer = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ContentHeaderInfoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const ContentHeaderChip = styled(Typography)`
  &&& {
    width: 42px;
    height: 24px;
    background: #a5a5a5;
    font-family: Pretendard;
    font-size: 13px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
  }
`;

export const ContentHeaderResponseChip = styled(Typography)`
  &&& {
    width: 42px;
    height: 24px;
    background: #5135a4;
    font-family: Pretendard;
    font-size: 13px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
  }
`;

export const ContentHeaderTypo = styled(Typography)`
  &&& {
    font-family: Pretendard;
    font-size: 14px;
    color: #333;
  }
`;
export const ContentResponseImg = styled.img`
  width: 16px;
`;
