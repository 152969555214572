import styled from 'styled-components';

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
`;

export const NodeContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 10px;
`;

export const Bar = styled.div`
  width: 1px;
  height: 20px;
  background: #333;
`;

export const ChipWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Chip = styled.div`
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px #333 solid;
  border-radius: 40px;
`;

type LineProps = {
  a: number;
  b: number;
};

export const Line = styled.div<LineProps>`
  width: ${(props) => `calc(100% - ${props.a}px - ${props.b}px)`};
  height: 1px;
  background: #333;
  margin-left: ${(props) => props.a}px;
`;

export const SubLine = styled.div`
  width: calc(100% - 80px);
  min-width: 1px;
  height: 1px;
  background: #333;
`;
