import React, { useEffect, useState } from 'react';
import Header from 'systems/Header';
import {
  Container,
  ContentContainer,
  ContentDetailsContainer,
  ContentDetailsTypo,
  ContentHeaderContainer,
  ContentHeaderDateTypo,
  ContentHeaderTypo,
  ListButton,
  ListButtonContainer,
  NavigateContainer,
  NavigateItemContainer,
  NavigateItemContentContainer,
  NavigateItemHeaderContainer,
  Root,
  SearchBarButton,
  SearchBarContainer,
  SearchBarInput,
  SearchBarSelect,
  TableContainer,
  TableContentContainer,
  TableContentTypo,
  TableHeaderContainer,
  TableHeaderTypo,
} from './styled';
import SubBanner from 'systems/SubBanner';
import NoticeNavigator from 'systems/NoticeNavigator';
import { commonAxios } from 'api/commonAxios';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import MobileHeader from 'systems/Header/Mobile';
import MobileSubBanner from 'systems/SubBanner/Mobile';
import MobileNoticeNavigator from 'systems/NoticeNavigator/Mobile';
import MobileNoticeSearchBar from 'systems/NoticeSearchBar/Mobile';
import MobileFooter from 'systems/Footer/Mobile';

type NoticeType = {
  id: number;
  title: string;
  content: string;
  created_at: string;
};

const MobileNoticeDetails = () => {
  const location = useLocation();
  const [data, setData] = useState<{
    current: NoticeType;
    prev: NoticeType;
    next: NoticeType;
  }>();

  useEffect(() => {
    commonAxios({
      url: `notices/${location.pathname.split('/')[3]}`,
      method: 'GET',
    }).then((res) => {
      if (res.data?.current) {
        setData(res.data);
      }
    });
  }, []);

  const onClickButton = () => {
    window.location.href = `/notice/`;
  };

  const onClickNavigate = (id?: number) => () => {
    if (id) {
      window.location.href = `/notice/details/${id}`;
    }
  };

  return (
    <Root>
      <MobileHeader />
      <MobileSubBanner label='공지사항' imgType='3' />
      <MobileNoticeNavigator />
      <MobileNoticeSearchBar />
      <Container>
        <ContentContainer>
          <ContentHeaderContainer>
            <ContentHeaderTypo>{data?.current.title}</ContentHeaderTypo>
            <ContentHeaderDateTypo>
              {data?.current.created_at &&
                moment(data?.current.created_at).format('YYYY.MM.DD')}
            </ContentHeaderDateTypo>
          </ContentHeaderContainer>
          <ContentDetailsContainer>
            <ContentDetailsTypo>{data?.current.content}</ContentDetailsTypo>
          </ContentDetailsContainer>
        </ContentContainer>
        <ListButtonContainer>
          <ListButton onClick={onClickButton}>목록</ListButton>
        </ListButtonContainer>
        <NavigateContainer>
          <NavigateItemContainer onClick={onClickNavigate(data?.next.id)}>
            <NavigateItemHeaderContainer>다음글</NavigateItemHeaderContainer>
            <NavigateItemContentContainer>
              {data?.next.title}
            </NavigateItemContentContainer>
          </NavigateItemContainer>
          <NavigateItemContainer onClick={onClickNavigate(data?.prev.id)}>
            <NavigateItemHeaderContainer>이전글</NavigateItemHeaderContainer>
            <NavigateItemContentContainer>
              {data?.prev.title}
            </NavigateItemContentContainer>
          </NavigateItemContainer>
        </NavigateContainer>
      </Container>
      <MobileFooter />
    </Root>
  );
};

export default MobileNoticeDetails;
