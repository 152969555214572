import React from 'react';
import Header from 'systems/Header';
import SubBanner from 'systems/SubBanner';
import { ContentImg, ContentImgWrapper, Root } from './styled';
import intro_content from 'assets/images/mobile_intro_content.png';
import MobileHeader from 'systems/Header/Mobile';
import MobileSubBanner from 'systems/SubBanner/Mobile';

const MobileIntro = () => {
  return (
    <Root>
      <MobileHeader />
      <MobileSubBanner imgType='2' label='GG레버러지 소개' />
      <ContentImgWrapper>
        <ContentImg src={intro_content} />
      </ContentImgWrapper>
    </Root>
  );
};

export default MobileIntro;
