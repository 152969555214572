import { Typography } from 'antd';
import styled from 'styled-components';

export const Root = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 56px;
`;

export const ContentImgWrapper = styled.div`
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  margin-top: 48px;
`;

export const ContentImg = styled.img`
  width: 100%;
`;

export const MenuContainer = styled.div`
  width: 100%;
  height: 63px;
  background: #f9f9f9;
  display: flex;
  align-items: center;
  gap: 35px;
  padding: 0 30px;
  box-sizing: border-box;
  border-bottom: 1px #d9d9d9 solid;
  overflow-x: scroll;
`;

export const MenuTypoWrapper = styled.div`
  height: 100%;
`;

type MenuTypoProps = {
  isPointed: boolean;
};

export const MenuTypo = styled(Typography)<MenuTypoProps>`
  &&& {
    display: flex;
    align-items: center;
    height: 100%;
    font-family: Pretendard;
    font-size: 14px;
    ${(props) =>
      props.isPointed &&
      `font-weight: bold;
    border-bottom: 4px #333 solid; padding-top: 4px;`}
    box-sizing: border-box;
    white-space: nowrap;
  }
`;
