import { Button, Input, Select, Typography } from 'antd';
import styled from 'styled-components';

export const Root = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-top: 150px;
`;

export const BannerImg = styled.img`
  width: 1920px;
  height: 170px;
`;

export const ContentImg = styled.img`
  width: 1062px;
`;

export const Container = styled.div`
  width: 1000px;
  display: flex;
  flex-direction: column;
  padding-bottom: 150px;
`;

export const SearchBarContainer = styled.div`
  background: #f9f9f9;
  display: flex;
  justify-content: center;
  gap: 10px;
  padding: 30px;
  box-sizing: border-box;
  margin-top: 62px;
`;

export const SearchBarSelect = styled(Select)`
  width: 115px;
  height: 40px;
  .ant-select-selector {
    &&& {
      height: 40px;
    }
  }
`;

export const SearchBarInput = styled(Input)`
  width: 376px;
  height: 40px;
`;

export const SearchBarButton = styled(Button)`
  width: 76px;
  height: 40px;
  background: #5135a4;
  font-family: Pretendard;
  font-size: 14px;
  color: #fff;
`;

export const TableContainer = styled.div`
  width: 1000px;
  border-top: 1px #333 solid;
  margin-top: 80px;
`;

export const TableHeaderContainer = styled.div`
  width: 100%;
  height: 50px;
  background: #f9f9f9;
  display: flex;
  align-items: center;
  border-bottom: 1px #e3e3e3 solid;
`;

type TableHeaderTypoProps = {
  width: number;
};

export const TableHeaderTypo = styled(Typography)<TableHeaderTypoProps>`
  width: ${(props) => props.width}px;
  height: 50px;
  font-family: Pretendard;
  font-size: 16px;
  font-weight: bold;
  color: #333;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px #e3e3e3 solid;
  &:last-child {
    border-right: 0;
  }
`;

export const TableContentContainer = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  border-bottom: 1px #e3e3e3 solid;
  cursor: pointer;
`;

type TableContentTypoProps = {
  width: number;
};

export const TableContentTypo = styled(Typography)<TableContentTypoProps>`
  width: ${(props) => props.width}px;
  height: 50px;
  font-family: Pretendard;
  font-size: 16px;
  color: #333;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px #e3e3e3 solid;
  &:last-child {
    border-right: 0;
  }
`;
