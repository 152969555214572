import { message } from 'antd';
import React, { useState } from 'react';
import {
  SearchBarButton,
  SearchBarContainer,
  SearchBarInput,
  SearchBarSelect,
} from './styled';

const NoticeSearchBar = () => {
  const [select, setSelect] = useState<any>(1);
  const [value, setValue] = useState('');

  const onClickSearch = () => {
    if (value === '') {
      message.error('검색어를 입력해주세요.');
      return;
    }
    window.location.href = `/search/${select}/${value}`;
  };

  return (
    <SearchBarContainer>
      <SearchBarSelect
        value={select}
        onChange={(value) => setSelect(value)}
        size='large'
      >
        <SearchBarSelect.Option value={1}>전체</SearchBarSelect.Option>
        <SearchBarSelect.Option value={2}>공지사항</SearchBarSelect.Option>
        <SearchBarSelect.Option value={3}>
          자주 묻는 질문
        </SearchBarSelect.Option>
      </SearchBarSelect>
      <SearchBarInput
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onKeyDown={(e) => e.key === 'Enter' && onClickSearch()}
      />
      <SearchBarButton onClick={onClickSearch}>검색</SearchBarButton>
    </SearchBarContainer>
  );
};

export default NoticeSearchBar;
