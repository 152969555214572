import React, { useEffect, useState } from 'react';
import Header from 'systems/Header';
import {
  Bar,
  Container,
  ContentButton,
  InfoContainer,
  InfoContentContainer,
  InfoHeaderContainer,
  InfoIdContainer,
  InfoIdInnerContainer,
  InfoIdInnerTypo,
  InfoItem,
  InfoPersonalContainer,
  InfoPersonalInnerContainer,
  InfoPersonalInnerContentButton,
  InfoPersonalInnerContentTypo,
  InfoPersonalInnerHeaderTypo,
  InfoPersonalInnerLineContainer,
  InfoTable,
  RecentTitleTypo,
  RentApplyButton,
  RentApplyButtonWrapper,
  RentApplyContainer,
  RentContainer,
  RentModalButton,
  RentModalButtonWrapper,
  RentModalItemBar,
  RentModalItemContainer,
  RentModalItemContentTypo,
  RentModalItemHeaderTypo,
  RentModalRoot,
  RentRecentContainer,
  RentRecentContentContainer,
  RentRecentHeaderContainer,
  RentRecentItem,
  RentRecentItemButton,
  RentRecentTable,
  Root,
  SharingBonusContainer,
  SharingBonusInfoContentcontainer,
  SharingBonusInfoHeaderContainer,
  SharingBonusInfoHeaderTypo,
  SharingBonusInfoTable,
  SharingBonusTable,
  SharingBonusTableDateContainer,
  SharingBonusTableDateTitleTypo,
  SharingBonusTitleTypo,
  TitleTypo,
} from './styled';
import SubBanner from 'systems/SubBanner';
import { commonAxios } from 'api/commonAxios';
import moment from 'moment';
import { Modal } from 'antd';
import { isMobile } from 'react-device-detect';
import MobileMyPage from './Mobile';
import Footer from 'systems/Footer';
import ParentModal from 'systems/ParentModal';
import { numberFormat } from 'utils/format';

const MyPage = () => {
  const [userData, setUserData] = useState<any>();
  const [memberData, setMemberData] = useState<any>();
  const [memberData2, setMemberData2] = useState<any>();
  const [rentData, setRentData] = useState<any[]>();
  const [sharingData, setSharingData] = useState<any[]>();
  const [rentDataDetails, setRentDataDetails] = useState<any>();
  const [rentId, setRentId] = useState<number>(0);
  const [parentMemberData, setParentMemberData] = useState<any[]>([]);
  const [parentMemberData2, setParentMemberData2] = useState<any>([]);
  const [openParent, setOpenParent] = useState<boolean>(false);
  const [myId, setMyId] = useState<any>();

  let accessToken = localStorage.getItem('access_token');

  const onClickMemeber = (username: string) => () => {
    commonAxios({
      url: `referrals/${username}`,
      method: 'GET',
      Authorization: `Bearer ${accessToken}`,
    }).then((res) => {
      setParentMemberData2(res.data?.parent);
      setParentMemberData(res.data?.referral_nodes);
    });
  };

  useEffect(() => {
    if (accessToken) {
      commonAxios({
        url: 'auth/profile',
        method: 'GET',
        Authorization: `Bearer ${accessToken}`,
      }).then((res) => {
        if (res.data?.user_info) {
          setUserData(res.data.user_info);
          setMemberData(res.data.referral_nodes);
          setMemberData2(res.data?.parent);
          setMyId(res.data?.hierarchy.id);
        }
      });
      commonAxios({
        url: 'rentals',
        method: 'GET',
        Authorization: `Bearer ${accessToken}`,
      }).then((res) => {
        if (res.data) {
          setRentData(res.data);
        }
      });
      commonAxios({
        url: 'sharing_bonuses/',
        method: 'GET',
        Authorization: `Bearer ${accessToken}`,
      }).then((res) => {
        if (res.data) {
          setSharingData(res.data);
        }
      });
    } else {
      alert('로그인이 필요한 페이지입니다.');
      window.location.replace('/');
    }
  }, []);

  useEffect(() => {
    commonAxios({
      url: `rentals/${rentId}`,
      method: 'GET',
      Authorization: `Bearer ${accessToken}`,
    }).then((res) => {
      if (res.data) {
        setRentDataDetails(res.data);
      }
    });
  }, [rentId]);

  if (isMobile) {
    return <MobileMyPage />;
  }

  console.log(memberData2, memberData);

  return (
    <Root>
      <Header />
      <SubBanner label='마이페이지' imgType='4' />
      <Container>
        <TitleTypo>나의 조직</TitleTypo>
        <Bar style={{ marginTop: '12px', marginBottom: '50px' }} />
        <InfoContainer>
          <InfoPersonalContainer>
            <TitleTypo>본인정보</TitleTypo>
            <InfoPersonalInnerContainer>
              <InfoPersonalInnerLineContainer>
                <InfoPersonalInnerHeaderTypo>성명</InfoPersonalInnerHeaderTypo>
                <InfoPersonalInnerContentTypo>
                  {userData?.name}
                </InfoPersonalInnerContentTypo>
                <InfoPersonalInnerHeaderTypo>
                  휴대전화
                </InfoPersonalInnerHeaderTypo>
                <InfoPersonalInnerContentTypo>
                  {userData?.phone_number}
                </InfoPersonalInnerContentTypo>
              </InfoPersonalInnerLineContainer>
              <InfoPersonalInnerLineContainer>
                <InfoPersonalInnerHeaderTypo>주소</InfoPersonalInnerHeaderTypo>
                <InfoPersonalInnerContentTypo>
                  {userData?.address}
                </InfoPersonalInnerContentTypo>
                <InfoPersonalInnerHeaderTypo>
                  이메일
                </InfoPersonalInnerHeaderTypo>
                <InfoPersonalInnerContentTypo>
                  {userData?.email}
                </InfoPersonalInnerContentTypo>
              </InfoPersonalInnerLineContainer>
              <InfoPersonalInnerLineContainer>
                <InfoPersonalInnerHeaderTypo>생일</InfoPersonalInnerHeaderTypo>
                <InfoPersonalInnerContentTypo>
                  {userData?.birthday}
                </InfoPersonalInnerContentTypo>
              </InfoPersonalInnerLineContainer>
            </InfoPersonalInnerContainer>
          </InfoPersonalContainer>
          <InfoIdContainer>
            <TitleTypo>아이디</TitleTypo>
            <InfoIdInnerContainer>
              <InfoIdInnerTypo>{userData?.username}</InfoIdInnerTypo>
            </InfoIdInnerContainer>
          </InfoIdContainer>
        </InfoContainer>
        <ContentButton onClick={() => setOpenParent(true)}>
          계보도 보기
        </ContentButton>
        <InfoTable>
          <InfoHeaderContainer>
            <InfoItem width={333}>성명</InfoItem>
            <InfoItem width={333}>타이틀</InfoItem>
            <InfoItem width={333}>대여금합계</InfoItem>
          </InfoHeaderContainer>
          {memberData2 && (
            <InfoContentContainer>
              <InfoItem width={333}>{memberData2?.realname}</InfoItem>
              <InfoItem width={333}>{memberData2?.referral_type}</InfoItem>
              <InfoItem width={333}>
                {memberData2?.total_rental_amount &&
                  numberFormat(memberData2?.total_rental_amount)}
                원
              </InfoItem>
            </InfoContentContainer>
          )}
          {memberData &&
            memberData?.map((value: any, index: number) => (
              <InfoContentContainer>
                <InfoItem width={333}>{value?.realname}</InfoItem>
                <InfoItem width={333}>{value?.referral_type}</InfoItem>
                <InfoItem width={333}>
                  {value?.total_rental_amount &&
                    numberFormat(value?.total_rental_amount)}
                  원
                </InfoItem>
              </InfoContentContainer>
            ))}
        </InfoTable>
        <SharingBonusContainer>
          <SharingBonusTitleTypo>쉐어링 보너스 관리</SharingBonusTitleTypo>
          <SharingBonusTable>
            <SharingBonusTableDateContainer>
              <SharingBonusTableDateTitleTypo>
                모든 정보 보기
              </SharingBonusTableDateTitleTypo>
            </SharingBonusTableDateContainer>
            <SharingBonusInfoTable>
              <SharingBonusInfoHeaderContainer>
                <SharingBonusInfoHeaderTypo>지급월</SharingBonusInfoHeaderTypo>
                <SharingBonusInfoHeaderTypo>
                  차월 예상지급액(세전)
                </SharingBonusInfoHeaderTypo>
                <SharingBonusInfoHeaderTypo>
                  쉐어링 보너스 내역
                </SharingBonusInfoHeaderTypo>
              </SharingBonusInfoHeaderContainer>
              {sharingData?.map((value, index) => {
                return value.sharing_bonuses.length > 0 ? (
                  <>
                    <SharingBonusInfoContentcontainer>
                      <SharingBonusInfoHeaderTypo
                        style={{ marginLeft: '-10px' }}
                      >
                        {value.label}
                      </SharingBonusInfoHeaderTypo>
                      {/* <SharingBonusInfoHeaderTypo>
                        {value.total_amount} 원
                      </SharingBonusInfoHeaderTypo> */}
                    </SharingBonusInfoContentcontainer>
                    {value?.sharing_bonuses.map((value2: any, index2: any) => {
                      return (
                        <SharingBonusInfoHeaderContainer>
                          <SharingBonusInfoHeaderTypo
                            style={{ marginLeft: '45px' }}
                          >
                            월간
                          </SharingBonusInfoHeaderTypo>
                          <SharingBonusInfoHeaderTypo
                            style={{ marginLeft: '-50px', width: '180px' }}
                          >
                            {value2.amount && numberFormat(value2.amount)} 원
                          </SharingBonusInfoHeaderTypo>
                          <SharingBonusInfoHeaderTypo
                            style={{
                              color: '#008AB0',
                              width: '150px',
                              marginLeft: '25px',
                              cursor: 'pointer',
                            }}
                          >
                            상세 내역 조회
                          </SharingBonusInfoHeaderTypo>
                        </SharingBonusInfoHeaderContainer>
                      );
                    })}
                  </>
                ) : (
                  <></>
                );
              })}
            </SharingBonusInfoTable>
          </SharingBonusTable>
        </SharingBonusContainer>
        <RentContainer>
          <RentApplyContainer>
            <RecentTitleTypo>대여신청</RecentTitleTypo>
            <RentApplyButtonWrapper>
              <RentApplyButton
                onClick={() => (window.location.href = `/mypage/paper`)}
                type='primary'
              >
                대여신청
              </RentApplyButton>
            </RentApplyButtonWrapper>
          </RentApplyContainer>
          <RentRecentContainer>
            <RecentTitleTypo>대여현황</RecentTitleTypo>
            <RentRecentTable>
              <RentRecentHeaderContainer>
                <RentRecentItem width={224}>대여인</RentRecentItem>
                <RentRecentItem width={224}>대여일</RentRecentItem>
                <RentRecentItem width={224}>현황표</RentRecentItem>
              </RentRecentHeaderContainer>
              {rentData?.map((value, index) => {
                return (
                  <RentRecentContentContainer>
                    <>
                      <RentRecentItem width={224}>
                        {value.username}
                      </RentRecentItem>
                      <RentRecentItem width={224}>
                        {value.created_at
                          ? moment(value.created_at).format('YYYY.MM.DD')
                          : null}
                      </RentRecentItem>
                      <RentRecentItem width={224}>
                        <RentRecentItemButton
                          type='primary'
                          onClick={() => setRentId(value.id)}
                        >
                          현황표 보기
                        </RentRecentItemButton>
                      </RentRecentItem>
                    </>
                  </RentRecentContentContainer>
                );
              })}
            </RentRecentTable>
          </RentRecentContainer>
        </RentContainer>
      </Container>
      <Modal
        visible={rentId !== 0}
        onCancel={() => setRentId(0)}
        footer={null}
        width={580}
        title='현황표'
      >
        <RentModalRoot>
          <RentModalItemContainer>
            <RentModalItemHeaderTypo>대여인</RentModalItemHeaderTypo>
            <RentModalItemContentTypo>
              {rentDataDetails?.username}
            </RentModalItemContentTypo>
          </RentModalItemContainer>
          <RentModalItemContainer>
            <RentModalItemHeaderTypo>주소</RentModalItemHeaderTypo>
            <RentModalItemContentTypo>
              {rentDataDetails?.address}
            </RentModalItemContentTypo>
          </RentModalItemContainer>
          <RentModalItemContainer>
            <RentModalItemHeaderTypo>
              주민등록번호
              <br />
              (사업자등록번호)
            </RentModalItemHeaderTypo>
            <RentModalItemContentTypo>
              {rentDataDetails?.social_security_number}
            </RentModalItemContentTypo>
          </RentModalItemContainer>
          <RentModalItemContainer>
            <RentModalItemHeaderTypo>핸드폰번호</RentModalItemHeaderTypo>
            <RentModalItemContentTypo>
              {rentDataDetails?.phone_number}
            </RentModalItemContentTypo>
          </RentModalItemContainer>
          <RentModalItemBar />
          <RentModalItemContainer>
            <RentModalItemHeaderTypo>차용인</RentModalItemHeaderTypo>
            <RentModalItemContentTypo>
              GG레버리지 남태훈
            </RentModalItemContentTypo>
          </RentModalItemContainer>
          <RentModalItemContainer>
            <RentModalItemHeaderTypo>주소</RentModalItemHeaderTypo>
            <RentModalItemContentTypo>
              서울시 강남구 테헤란로 82길15 1288호(대치동, 디아이타워)
            </RentModalItemContentTypo>
          </RentModalItemContainer>
          <RentModalItemContainer>
            <RentModalItemHeaderTypo>
              주민등록번호
              <br />
              (사업자등록번호)
            </RentModalItemHeaderTypo>
            <RentModalItemContentTypo>894-05-02368</RentModalItemContentTypo>
          </RentModalItemContainer>
          <RentModalItemContainer>
            <RentModalItemHeaderTypo>핸드폰번호</RentModalItemHeaderTypo>
            <RentModalItemContentTypo>010-2886-3505</RentModalItemContentTypo>
          </RentModalItemContainer>
          <RentModalItemBar />
          <RentModalItemContainer>
            <RentModalItemHeaderTypo>대여금액</RentModalItemHeaderTypo>
            <RentModalItemContentTypo>
              {rentDataDetails?.amount && numberFormat(rentDataDetails?.amount)}
              원
            </RentModalItemContentTypo>
          </RentModalItemContainer>
          <RentModalItemContainer>
            <RentModalItemHeaderTypo>대여금이자</RentModalItemHeaderTypo>
            <RentModalItemContentTypo>24%</RentModalItemContentTypo>
          </RentModalItemContainer>
          <RentModalItemContainer>
            <RentModalItemHeaderTypo></RentModalItemHeaderTypo>
            <RentModalItemContentTypo>
              {rentDataDetails?.bank_account_type}
            </RentModalItemContentTypo>
          </RentModalItemContainer>
          <RentModalItemContainer>
            <RentModalItemHeaderTypo></RentModalItemHeaderTypo>
            <RentModalItemContentTypo>
              {rentDataDetails?.bank_account_number}
            </RentModalItemContentTypo>
          </RentModalItemContainer>
          <RentModalItemContainer>
            <RentModalItemHeaderTypo></RentModalItemHeaderTypo>
            <RentModalItemContentTypo>
              {rentDataDetails?.bank_account_owner_name}
            </RentModalItemContentTypo>
          </RentModalItemContainer>
          <RentModalItemBar />
          <RentModalItemContainer>
            <RentModalItemHeaderTypo>변제기일</RentModalItemHeaderTypo>
            <RentModalItemContentTypo>
              {rentDataDetails?.due_date}
            </RentModalItemContentTypo>
          </RentModalItemContainer>
          {/* <RentModalItemContainer>
            <RentModalItemHeaderTypo>변제인 정보</RentModalItemHeaderTypo>
            <RentModalItemContentTypo>-</RentModalItemContentTypo>
          </RentModalItemContainer>
          <RentModalItemContainer>
            <RentModalItemHeaderTypo></RentModalItemHeaderTypo>
            <RentModalItemContentTypo>-</RentModalItemContentTypo>
          </RentModalItemContainer>
          <RentModalItemContainer>
            <RentModalItemHeaderTypo></RentModalItemHeaderTypo>
            <RentModalItemContentTypo>-</RentModalItemContentTypo>
          </RentModalItemContainer> */}
          <RentModalButtonWrapper>
            <RentModalButton onClick={() => setRentId(0)} type='primary'>
              확인
            </RentModalButton>
          </RentModalButtonWrapper>
        </RentModalRoot>
      </Modal>
      <Modal
        visible={parentMemberData.length !== 0}
        onCancel={() => setParentMemberData([])}
        footer={null}
        title='상위 계보'
      >
        <InfoTable style={{ marginTop: 0 }}>
          <InfoHeaderContainer>
            <InfoItem width={333}>성명</InfoItem>
            <InfoItem width={333}>타이틀</InfoItem>
            <InfoItem width={333}>대여금합계</InfoItem>
          </InfoHeaderContainer>
          {parentMemberData2 && (
            <InfoContentContainer>
              <InfoItem width={333}>
                <InfoPersonalInnerContentButton
                  onClick={onClickMemeber(parentMemberData2.name)}
                  type='primary'
                >
                  {parentMemberData2?.realname}
                </InfoPersonalInnerContentButton>
              </InfoItem>
              <InfoItem width={333}>
                {parentMemberData2?.referral_type}
              </InfoItem>
              <InfoItem width={333}>
                {parentMemberData2?.total_rental_amount &&
                  new Intl.NumberFormat(parentMemberData2?.total_rental_amount)}
                원
              </InfoItem>
            </InfoContentContainer>
          )}
          {parentMemberData &&
            parentMemberData?.map((value: any, index: number) => (
              <InfoContentContainer>
                <InfoItem width={333}>
                  <InfoPersonalInnerContentButton
                    onClick={onClickMemeber(value.name)}
                    type='primary'
                  >
                    {value?.realname}
                  </InfoPersonalInnerContentButton>
                </InfoItem>
                <InfoItem width={333}>{value?.referral_type}</InfoItem>
                <InfoItem width={333}>{value?.total_rental_amount} 원</InfoItem>
              </InfoContentContainer>
            ))}
        </InfoTable>
      </Modal>
      <ParentModal
        parent={memberData2}
        childrenList={memberData?.filter(
          (value: any, index: number) => index > 0
        )}
        me={userData}
        visible={openParent}
        myId={myId}
        onCancel={() => setOpenParent(false)}
      />
      <Footer />
    </Root>
  );
};

export default MyPage;
