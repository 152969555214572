import { Typography } from 'antd';
import styled from 'styled-components';

export const Root = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  position: relative;
`;

export const BannerContainer = styled.div`
  width: 100%;
  height: calc(100vh - 56px);
  position: relative;
  margin-top: 56px;
  overflow: hidden;
`;

export const BannerVideo = styled.video`
  height: calc(100vh - 56px);
  position: absolute;
  top: 0;
  left: -300px;
`;

export const BannerTypo = styled(Typography)`
  &&& {
    font-size: 40px;
    font-weight: bold;
    color: white;
    position: absolute;
    top: 308px;
    left: 10px;
  }
`;

export const BannerImg = styled.img`
  width: 100%;
`;

export const BannerScrollDown = styled.img`
  width: 45px;
  height: 45px;
  position: absolute;
  left: calc(50% - 22.5px);
  bottom: 60px;
  cursor: pointer;
  z-index: 5;
`;

export const ContentImg = styled.img`
  width: 375px;
  padding-bottom: 100px;
  margin-top: 60px;
  z-index: 0;
`;

export const ContentSharingImg = styled.img`
  width: 320px;
  position: absolute;
  top: 2700px;
  z-index: 5;
`;

export const BannerVideo2Wrapper = styled.div`
  width: 320px;
  height: 200px;
  position: absolute;
  top: 5750px;
  left: calc(50% - 160px);
  z-index: 5;
`;

export const BannerVideo2 = styled.video`
  width: 320px;
  height: 200px;
`;

export const BannerVideo3Wrapper = styled.div`
  min-width: 10px;
  width: 320px;
  height: 250px;
  background: #333;
  position: absolute;
  top: 5750px;
  left: calc(50% - 160px);
  z-index: 6;
`;

export const BannerVideo3 = styled.video`
  width: 320px;
`;
