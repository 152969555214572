import React from 'react';
import Header from 'systems/Header';
import SubBanner from 'systems/SubBanner';
import { ContentImg, Root } from './styled';
import loan_content from 'assets/images/loan_content.png';
import { isMobile } from 'react-device-detect';
import MobileLoan from './Mobile';
import Footer from 'systems/Footer';

const Loan = () => {
  if (isMobile) {
    return <MobileLoan />;
  }

  return (
    <Root>
      <Header />
      <SubBanner imgType='2' label='대여금' />
      <ContentImg src={loan_content} />
      <Footer />
    </Root>
  );
};

export default Loan;
