import React, { useEffect, useState } from 'react';
import Header from 'systems/Header';
import {
  Container,
  ContentCollapse,
  ContentCollapseArrowDown,
  ContentCollapseContainer,
  ContentCollapseQ,
  ContentCollapseTypo,
  ContentCollapseTypoContainer,
  ContentContainer,
  ContentPanel,
  ContentPanelTypo,
  Root,
  SearchBarButton,
  SearchBarContainer,
  SearchBarInput,
  SearchBarSelect,
  TableContainer,
  TableContentContainer,
  TableContentTypo,
  TableHeaderContainer,
  TableHeaderTypo,
} from './styled';
import SubBanner from 'systems/SubBanner';
import NoticeNavigator from 'systems/NoticeNavigator';
import { commonAxios } from 'api/commonAxios';
import { ContentInput } from 'pages/Login/styled';
import { isMobile } from 'react-device-detect';
import NoticeSearchBar from 'systems/NoticeSearchBar';
import arrowBottom from 'assets/images/arrow_bottom.png';
import qImg from 'assets/images/Q.png';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import MobileSearch from './Mobile';
import Footer from 'systems/Footer';

type NoticeType2 = {
  id: number;
  title: string;
  content: string;
  created_at: string;
  viewed_count: number;
};

type NoticeType = {
  title: string;
  content: string;
  created_at: string;
};

const Search = () => {
  const [dataList, setDataList] = useState<NoticeType2[]>();
  const [dataList2, setDataList2] = useState<NoticeType[]>();
  const [dataList3, setDataList3] = useState<NoticeType[]>();
  const location = useLocation();
  const select = location.pathname.split('/')[2];
  const value = location.pathname.split('/')[3];

  useEffect(() => {
    commonAxios({
      url: 'notices/',
      method: 'GET',
      params: { search: decodeURIComponent(value) },
    }).then((res) => {
      setDataList(res.data);
    });
    commonAxios({
      url: 'faqs/',
      method: 'GET',
      params: { search: decodeURIComponent(value) },
    }).then((res) => {
      setDataList2(res.data);
    });
  }, []);

  const onClickTable = (id: number) => () => {
    window.location.href = `/notice/details/${id}`;
  };

  if (isMobile) {
    return <MobileSearch />;
  }

  return (
    <Root>
      <Header />
      <SubBanner label='공지사항' imgType='3' />
      <NoticeNavigator type='5' />
      <Container>
        <NoticeSearchBar />
        {(select === '1' || select === '2') && (
          <TableContainer>
            <TableHeaderContainer>
              <TableHeaderTypo width={90}>NO.</TableHeaderTypo>
              <TableHeaderTypo width={677}>제목</TableHeaderTypo>
              <TableHeaderTypo width={100}>조회수</TableHeaderTypo>
              <TableHeaderTypo width={130}>등록일</TableHeaderTypo>
            </TableHeaderContainer>
            {dataList?.map((value, index) => (
              <TableContentContainer
                onClick={onClickTable(value.id)}
                key={`table_content_container_${index}`}
              >
                <TableContentTypo width={90}>{value.id}</TableContentTypo>
                <TableContentTypo
                  style={{ justifyContent: 'flex-start', paddingLeft: 30 }}
                  width={677}
                >
                  {value.title}
                </TableContentTypo>
                <TableContentTypo width={100}>
                  {value.viewed_count}
                </TableContentTypo>
                <TableContentTypo width={130}>
                  {moment(value.created_at).format('YYYY.MM.DD')}
                </TableContentTypo>
              </TableContentContainer>
            ))}
          </TableContainer>
        )}
        {(select === '1' || select === '3') && (
          <ContentContainer>
            <ContentCollapse ghost bordered>
              {dataList2?.map((value, index) => (
                <ContentPanel
                  showArrow={false}
                  header={
                    <ContentCollapseContainer>
                      <ContentCollapseTypoContainer>
                        <ContentCollapseQ src={qImg} />
                        <ContentCollapseTypo>{value.title}</ContentCollapseTypo>
                      </ContentCollapseTypoContainer>
                      <ContentCollapseArrowDown src={arrowBottom} />
                    </ContentCollapseContainer>
                  }
                  key={`${index}`}
                >
                  <ContentPanelTypo>{value.content}</ContentPanelTypo>
                </ContentPanel>
              ))}
            </ContentCollapse>
          </ContentContainer>
        )}
      </Container>
      <Footer />
    </Root>
  );
};

export default Search;
