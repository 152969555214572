import styled from 'styled-components';
import { Drawer, Typography } from 'antd';
import { MenuOutlined } from '@ant-design/icons';

export const Root = styled.div`
  width: 100vw;
  height: 56px;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.25);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
`;

export const InfoButtonTypo = styled(Typography)`
  font-family: Pretendard;
  font-size: 13px;
  font-weight: 500;
  color: white;
`;

export const Container = styled.div`
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  box-sizing: border-box;
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 7px;
  cursor: pointer;
`;

export const LogoTypo = styled.img`
  width: 41px;
`;

export const LogoImg = styled.img`
  width: 30px;
  height: 30px;
  cursor: pointer;
`;

export const MenuContainer = styled.div`
  display: flex;
  gap: 50px;
  z-index: 11;
`;

export const MenuTypoWrapper = styled.div`
  cursor: pointer;
`;

type MenuTypoProps = {
  isPointed: boolean;
};

export const MenuTypo = styled(Typography)<MenuTypoProps>`
  &&& {
    font-family: Pretendard;
    font-size: 24px;
    line-height: 1.2;
    ${(props) =>
      props.isPointed
        ? `
    
    font-weight: 700;
    color: #5135A4;`
        : `
    font-weight: 500;
    color: #333;`}
  }
`;

export const MenuImg = styled(MenuOutlined)`
  &&& {
    font-size: 16px;
    cursor: pointer;
  }
`;

export const ContentDrawer = styled(Drawer)`
  padding: 30px 45px;
`;

export const ContentDrawerBody = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`;

export const ContentDrawerHeaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ContentDrawerMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-top: 65px;
`;

export const ContentDrawerBar = styled.div`
  width: 100%;
  height: 1px;
  background: #d9d9d9;
  margin-top: 22px;
`;
