import { message, Modal } from 'antd';
import { commonAxios } from 'api/commonAxios';
import { bankList } from 'assets/constants/bank';
import React, { useState } from 'react';
import DaumPostcodeEmbed from 'react-daum-postcode';
import { isMobile } from 'react-device-detect';
import MobileFooter from 'systems/Footer/Mobile';
import Header from 'systems/Header';
import MobileHeader from 'systems/Header/Mobile';
import SubBanner from 'systems/SubBanner';
import MobileSubBanner from 'systems/SubBanner/Mobile';
import {
  Container,
  ContentAccountContainer,
  ContentAccountInput,
  ContentAccountInputContainer,
  ContentAccountLabelTypo,
  ContentAccountSelect,
  ContentAddressButton,
  ContentAddressContainer,
  ContentAddressInput,
  ContentAddressInputContainer,
  ContentAddressInputLineContainer,
  ContentAddressLabelTypo,
  ContentAgreeCheckbox,
  ContentAgreeContainer,
  ContentAgreeTypo,
  ContentBirthContainer,
  ContentBirthInput,
  ContentBirthLabelTypo,
  ContentContainer,
  ContentEmailContainer,
  ContentEmailInput,
  ContentEmailLabelTypo,
  ContentEtcContainer,
  ContentEtcInput,
  ContentEtcLabelTypo,
  ContentIdButton,
  ContentIdButtonTypo,
  ContentIdContainer,
  ContentIdInput,
  ContentIdInputContainer,
  ContentIdLabelTypo,
  ContentInfoContainer,
  ContentInfoGenderContainer,
  ContentInfoGenderLabelTypo,
  ContentInfoGenderSwitchButton,
  ContentInfoGenderSwitchContainer,
  ContentInfoNationContainer,
  ContentInfoNationLabelTypo,
  ContentInfoNationSwitchButton,
  ContentInfoNationSwitchContainer,
  ContentNameContainer,
  ContentNameInput,
  ContentNameLabelTypo,
  ContentPasswordContainer,
  ContentPasswordInput,
  ContentPasswordInputContainer,
  ContentPasswordLabelTypo,
  ContentRecommendContainer,
  ContentRecommendInput,
  ContentRecommendLabelTypo,
  ContentSubmitButton,
  ContentSubmitButtonTypo,
  ContentTitleTypo,
  Root,
} from './styled';

const Join = () => {
  const [id, setId] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [passwordConfirm, setPasswordConfirm] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [referee, setReferee] = useState<string>('');
  const [gender, setGender] = useState<'F' | 'M'>('M');
  const [country, setCountry] = useState<'IN' | 'OUT'>('IN');
  const [birth, setBirth] = useState<string>('');
  const [account, setAccount] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);
  const [address, setAddress] = useState<string>('');
  const [address2, setAddress2] = useState<string>('');
  const [etc, setEtc] = useState<string>('');
  const [agree, setAgree] = useState<boolean>(false);
  const [checkId, setCheckId] = useState<boolean>(false);
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [bank, setBank] = useState<string>('카카오뱅크');

  const handleComplete = (data: any) => {
    setAddress(data.address);
    setOpen(false);
  };

  const onClickIdDuplicate = () => {
    return commonAxios({
      url: 'auth/duplication',
      method: 'POST',
      data: { username: id },
    }).then((res) => {
      if (!res.data.is_duplicated) {
        setCheckId(true);
        message.info('중복 확인이 완료되었습니다.');
      } else {
        message.error('다른 아이디를 사용해주세요.');
      }
    });
  };

  const onClickSubmit = () => {
    if (id === '') {
      message.error('아이디를 입력해주세요.');
      return;
    }
    if (!checkId) {
      message.error('아이디 중복 체크를 해주세요.');
      return;
    }
    if (password === '') {
      message.error('비밀번호 입력해주세요.');
      return;
    }
    if (password !== passwordConfirm) {
      message.error('비밀번호와 비밀번호 확인이 일치하지 않습니다.');
      return;
    }
    if (name === '') {
      message.error('이름을 입력해주세요.');
      return;
    }
    // if (referee === '') {
    //   message.error('추천인을 입력해주세요.');
    //   return;
    // }
    if (birth === '') {
      message.error('생년월일을 입력해주세요.');
      return;
    }
    if (email === '') {
      message.error('이메일을 입력해주세요.');
      return;
    }
    if (!agree) {
      message.error('개인정보 처리방침에 동의해주세요.');
      return;
    }
    commonAxios({
      url: 'auth/signup',
      method: 'POST',
      data: {
        username: id,
        password,
        name,
        referral: referee,
        bank_account_number: `${bank} - ${account}`,
        email,
        address: `${address} - ${address2}`,
        etc: 'null',
        agreement: agree,
        phone_number: phoneNumber,
        birthday: birth,
      },
    })
      .then((res) => {
        if (res.data?.user?.name) {
          alert('회원가입이 완료되었습니다.');
          window.location.replace('/login');
        } else {
          alert('에러');
        }
      })
      .catch(() => {
        message.error('추천인 정보를 확인해주세요.');
      });
    return;
  };

  return (
    <Root>
      <MobileHeader />
      <MobileSubBanner label='회원가입' />
      <Container>
        <ContentContainer>
          <ContentTitleTypo>회원가입</ContentTitleTypo>
          <ContentIdContainer>
            <ContentIdLabelTypo>아이디</ContentIdLabelTypo>
            <ContentIdInputContainer>
              <ContentIdInput
                value={id}
                onChange={(e) => setId(e.target.value)}
                placeholder='아이디 입력'
                disabled={checkId}
              />
              <ContentIdButton onClick={onClickIdDuplicate} disabled={checkId}>
                <ContentIdButtonTypo>중복 확인</ContentIdButtonTypo>
              </ContentIdButton>
            </ContentIdInputContainer>
          </ContentIdContainer>
          <ContentPasswordContainer>
            <ContentPasswordLabelTypo>비밀번호</ContentPasswordLabelTypo>
            <ContentPasswordInputContainer>
              <ContentPasswordInput
                value={password}
                type='password'
                onChange={(e) => setPassword(e.target.value)}
                placeholder='비밀번호 입력'
              />
              <ContentPasswordInput
                value={passwordConfirm}
                type='password'
                onChange={(e) => setPasswordConfirm(e.target.value)}
                placeholder='비밀번호 확인'
              />
            </ContentPasswordInputContainer>
          </ContentPasswordContainer>
          <ContentNameContainer>
            <ContentNameLabelTypo>이름</ContentNameLabelTypo>
            <ContentNameInput
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder='이름 입력'
            />
          </ContentNameContainer>
          <ContentRecommendContainer>
            <ContentRecommendLabelTypo>
              추천인(아이디)
            </ContentRecommendLabelTypo>
            <ContentRecommendInput
              value={referee}
              onChange={(e) => setReferee(e.target.value)}
              placeholder='추천인(아이디) 입력'
            />
          </ContentRecommendContainer>
          <ContentInfoContainer>
            <ContentInfoGenderContainer>
              <ContentInfoGenderLabelTypo>성별</ContentInfoGenderLabelTypo>
              <ContentInfoGenderSwitchContainer>
                <ContentInfoGenderSwitchButton
                  onClick={() => setGender('M')}
                  isPointed={gender === 'M'}
                >
                  남
                </ContentInfoGenderSwitchButton>
                <ContentInfoGenderSwitchButton
                  onClick={() => setGender('F')}
                  isPointed={gender === 'F'}
                >
                  여
                </ContentInfoGenderSwitchButton>
              </ContentInfoGenderSwitchContainer>
            </ContentInfoGenderContainer>
            <ContentInfoNationContainer>
              <ContentInfoNationLabelTypo>국적</ContentInfoNationLabelTypo>
              <ContentInfoNationSwitchContainer>
                <ContentInfoNationSwitchButton
                  onClick={() => setCountry('IN')}
                  isPointed={country === 'IN'}
                >
                  내국인
                </ContentInfoNationSwitchButton>
                <ContentInfoNationSwitchButton
                  onClick={() => setCountry('OUT')}
                  isPointed={country === 'OUT'}
                >
                  외국인
                </ContentInfoNationSwitchButton>
              </ContentInfoNationSwitchContainer>
            </ContentInfoNationContainer>
          </ContentInfoContainer>
          <ContentBirthContainer>
            <ContentBirthLabelTypo>생일</ContentBirthLabelTypo>
            <ContentBirthInput
              placeholder='생일 8자리 입력'
              onChange={(e) => setBirth(e.target.value)}
              value={birth}
            />
          </ContentBirthContainer>
          <ContentAccountContainer>
            <ContentAccountLabelTypo>통장정보</ContentAccountLabelTypo>
            <ContentAccountInputContainer>
              <ContentAccountSelect
                size='large'
                value={bank}
                onChange={(e: any) => setBank(e)}
              >
                {bankList.map((value, index) => (
                  <ContentAccountSelect.Option value={value}>
                    {value}
                  </ContentAccountSelect.Option>
                ))}
              </ContentAccountSelect>
              <ContentAccountInput
                placeholder='계좌 번호 입력'
                onChange={(e) => setAccount(e.target.value)}
                value={account}
              />
            </ContentAccountInputContainer>
          </ContentAccountContainer>
          <ContentAccountContainer>
            <ContentAccountLabelTypo>핸드폰번호</ContentAccountLabelTypo>
            <ContentAccountInput
              placeholder='핸드폰번호 입력'
              onChange={(e) => setPhoneNumber(e.target.value)}
              value={phoneNumber}
            />
          </ContentAccountContainer>
          <ContentEmailContainer>
            <ContentEmailLabelTypo>이메일</ContentEmailLabelTypo>
            <ContentEmailInput
              placeholder='이메일 입력'
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
          </ContentEmailContainer>
          <ContentAddressContainer>
            <ContentAddressLabelTypo>주소(선택)</ContentAddressLabelTypo>
            <ContentAddressInputContainer>
              <ContentAddressInputLineContainer>
                <ContentAddressInput
                  value={address}
                  disabled={true}
                  placeholder='주소 입력'
                />
                <ContentAddressButton onClick={() => setOpen(true)}>
                  우편번호검색
                </ContentAddressButton>
              </ContentAddressInputLineContainer>
              <ContentAddressInput
                value={address2}
                disabled={address === ''}
                onChange={(e) => setAddress2(e.target.value)}
                placeholder='나머지 주소 입력'
              />
            </ContentAddressInputContainer>
          </ContentAddressContainer>
          <ContentAgreeContainer>
            <ContentAgreeCheckbox
              onClick={() => setAgree(true)}
              checked={agree}
            />
            <div onClick={() => window.open('/terms')}>
              <ContentAgreeTypo>개인정보 처리방침 및 동의하기</ContentAgreeTypo>
            </div>
          </ContentAgreeContainer>
          <ContentSubmitButton onClick={onClickSubmit}>
            <ContentSubmitButtonTypo>회원가입</ContentSubmitButtonTypo>
          </ContentSubmitButton>
        </ContentContainer>
      </Container>
      <Modal
        title='주소 검색'
        closable={true}
        visible={open}
        onCancel={() => setOpen(false)}
        footer={null}
      >
        <DaumPostcodeEmbed onComplete={handleComplete} className='post-code' />
      </Modal>
      <MobileFooter />
    </Root>
  );
};

export default Join;
