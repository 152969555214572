import React from 'react';
import Header from 'systems/Header';
import SubBanner from 'systems/SubBanner';
import { ContentImg, Root } from './styled';
import business_content from 'assets/images/business_content.png';
import { isMobile } from 'react-device-detect';
import MobileBusiness from './Mobile';

const Business = () => {
  if (isMobile) {
    return <MobileBusiness />;
  }
  return (
    <Root>
      <Header />
      <SubBanner imgType='2' label='비지니스' />
      <ContentImg src={business_content} />
    </Root>
  );
};

export default Business;
