import { Typography } from 'antd';
import styled from 'styled-components';

export const Root = styled.div`
  width: 100%;
  background: #333;
  display: flex;
  justify-content: center;
  padding: 20px 0;
`;

export const Container = styled.div`
  width: 1280px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ContentTypo = styled(Typography)`
  font-size: 16px;
  color: white;
`;

export const LogoImg = styled.img`
  width: 80px;
  height: 80px;
`;
