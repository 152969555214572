import React from 'react';
import {
  BannerContainer,
  BannerImg,
  BannerScrollDown,
  BannerTypo,
  BannerVideo,
  BannerVideo2,
  BannerVideo2Wrapper,
  BannerVideo3,
  BannerVideo3Wrapper,
  ContentImg,
  Root,
} from './styled';
import mainBanner from 'assets/images/main_banner.mp4';
import mainBanner2 from 'assets/images/main_banner_1.mp4';
import mainBanner3 from 'assets/images/main_banner_2.mp4';
import mainBannerScrollDown from 'assets/images/main_banner_scroll_down.png';
import mainContent from 'assets/images/main_content.png';
import Header from 'systems/Header';
import { isMobile } from 'react-device-detect';
import MobileMain from './Mobile';
import Footer from 'systems/Footer';

const Main = () => {
  const onClickScrollDown = () => {
    window.scrollTo({ top: 1080, behavior: 'smooth' });
  };

  if (isMobile) {
    return <MobileMain />;
  }

  return (
    <Root>
      <Header />
      <BannerContainer>
        <BannerVideo
          src={mainBanner}
          muted={true}
          autoPlay={true}
          loop={true}
        />
        <BannerTypo>GG LEVERAGE</BannerTypo>
        <BannerScrollDown
          src={mainBannerScrollDown}
          onClick={onClickScrollDown}
        />
        <BannerVideo2Wrapper>
          <BannerVideo2
            src={mainBanner2}
            muted={true}
            autoPlay={true}
            loop={true}
          />
        </BannerVideo2Wrapper>
        <BannerVideo3Wrapper>
          <BannerVideo3
            src={mainBanner3}
            muted={true}
            autoPlay={true}
            loop={true}
          />
        </BannerVideo3Wrapper>
      </BannerContainer>
      <ContentImg src={mainContent} />
      <Footer />
    </Root>
  );
};

export default Main;
