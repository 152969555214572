import styled from 'styled-components';

export const Root = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 56px;
`;

export const ContentImg = styled.img`
  width: 1062px;
`;

export const ContentContainer = styled.div`
  width: 100vw;
  padding: 0 20px;
  padding-bottom: 80px;
  margin-top: 40px;
`;
