import { Typography } from 'antd';
import styled from 'styled-components';

export const Root = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const BannerContainer = styled.div`
  width: 1920px;
  height: 1080px;
  position: relative;
`;

export const BannerImg = styled.img`
  width: 1920px;
  height: 1080px;
`;

export const BannerVideo = styled.video`
  width: 1920px;
  height: 1080px;
`;

export const BannerScrollDown = styled.img`
  width: 60px;
  height: 60px;
  position: absolute;
  left: calc(1920px / 2 - 30px);
  top: 90vh;
  cursor: pointer;
`;

export const ContentImg = styled.img`
  width: 1920px;
  height: 7500px;
`;

export const BannerTypo = styled(Typography)`
  &&& {
    font-size: 115px;
    font-weight: bold;
    color: white;
    position: absolute;
    top: 432px;
    left: 202px;
  }
`;

export const BannerVideo2Wrapper = styled.div`
  height: 394px;
  position: absolute;
  top: 2210px;
  left: 320px;
  z-index: 5;
  overflow: hidden;
`;

export const BannerVideo2 = styled.video`
  width: 1280px;
  margin-top: -160px;
`;

export const BannerVideo3Wrapper = styled.div`
  height: 450px;
  position: absolute;
  top: 7960px;
  left: 320px;
  z-index: 5;
  overflow: hidden;
`;

export const BannerVideo3 = styled.video`
  width: 1280px;
  margin-top: -20px;
`;
