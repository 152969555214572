import { Typography } from 'antd';
import styled from 'styled-components';

export const Root = styled.div`
  width: 100%;
  height: 62px;
  background: #f9f9f9;
  display: flex;
  justify-content: center;
  gap: 35px;
`;

export const MenuWrapper = styled.div`
  height: 62px;
  position: relative;
  cursor: pointer;
`;

type MenuTypoProps = {
  isPointed: boolean;
};

export const MenuTypo = styled(Typography)<MenuTypoProps>`
  &&& {
    height: 62px;
    font-family: Pretendard;
    font-size: 14px;
    color: #333;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    ${(props) =>
      props.isPointed
        ? `font-weight: 700; border-bottom: 4px #333 solid; margin-top: 2px;`
        : `font-weight: 400;`}
  }
`;
