import styled from 'styled-components';
import { Typography } from 'antd';

export const Root = styled.div`
  width: 100%;
  height: 150px;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.25);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
`;

export const InfoContainer = styled.div`
  width: 100%;
  height: 40px;
  background: #333;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const InfoInnerContainer = styled.div`
  width: 1280px;
  height: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const InfoButtonContainer = styled.div`
  display: flex;
  gap: 30px;
`;

export const InfoButton = styled.div`
  cursor: pointer;
`;

export const InfoButtonTypo = styled(Typography)`
  font-family: Pretendard;
  font-size: 13px;
  font-weight: 500;
  color: white;
`;

export const Container = styled.div`
  width: 1280px;
  height: 110px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`;

export const LogoImg = styled.img`
  width: 74px;
  height: 74px;
  cursor: pointer;
`;

export const LogoTypo = styled.img`
  width: 82px;
`;

export const MenuContainer = styled.div`
  display: flex;
  gap: 50px;
`;

export const MenuTypoWrapper = styled.div`
  cursor: pointer;
`;

type MenuTypoProps = {
  isPointed: boolean;
};

export const MenuTypo = styled(Typography)<MenuTypoProps>`
  &&& {
    font-family: Pretendard;
    font-size: 24px;
    ${(props) =>
      props.isPointed
        ? `
    
    font-weight: 700;
    color: #5135A4;`
        : `
    font-weight: 500;
    color: #333;`}
  }
`;
