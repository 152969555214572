import { Button, Input, Typography } from 'antd';
import styled from 'styled-components';

export const Root = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Container = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const ContentContainer = styled.div`
  width: 315px;
  height: 380px;
  background: #fff;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 150px;
`;

export const ContentTitleTypo = styled(Typography)`
  &&& {
    font-size: 28px;
    font-weight: bold;
    color: #333;
  }
`;

export const ContentInputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 7px;
  margin-top: 27px;
`;

export const ContentInput = styled(Input)`
  &&& {
    height: 40px;
    padding-left: 26px;
  }
`;

export const ContentSubmitButton = styled(Button)`
  &&& {
    width: 315px;
    height: 50px;
    background: #5135a4;
    border: 0;
    border-radius: 0;
    margin-top: 10px;
  }
`;

export const ContentSubmitButtonTypo = styled(Typography)`
  &&& {
    font-size: 16px;
    font-weight: 500;
    color: #fff;
  }
`;

export const ContentMenuContainer = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 26px;
  border: 1px #d9d9d9 solid;
  border-radius: 2px;
  margin-top: 20px;
`;

export const ContentMenuTypoWrapper = styled.div`
  cursor: pointer;
`;

export const ContentMenuTypo = styled(Typography)`
  &&& {
    font-size: 14px;
    color: #666;
  }
`;

export const ContentMenuBar = styled.div`
  width: 1px;
  height: 13px;
  background: #d9d9d9;
`;

export const ContentButton = styled(Button)`
  width: 100%;
  background: #5135a4;
  margin-top: 10px;
`;
