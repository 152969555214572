import React from 'react';
import {
  BannerContainer,
  BannerImg,
  BannerScrollDown,
  BannerTypo,
  BannerVideo,
  BannerVideo2,
  BannerVideo2Wrapper,
  BannerVideo3,
  BannerVideo3Wrapper,
  ContentImg,
  ContentSharingImg,
  Root,
} from './styled';
import mainBanner from 'assets/images/main_banner.mp4';
import mainBannerScrollDown from 'assets/images/main_banner_scroll_down.png';
import mainContent from 'assets/images/mobile_main_content.png';
import mainSharing1 from 'assets/images/mobile_main_sharing.png';
import MobileHeader from 'systems/Header/Mobile';
import MobileFooter from 'systems/Footer/Mobile';
import mainBanner2 from 'assets/images/main_banner_1.mp4';
import mainBanner3 from 'assets/images/main_banner_2_1.mp4';

const MobileMain = () => {
  const onClickScrollDown = () => {
    window.scrollTo({ top: 500, behavior: 'smooth' });
  };

  return (
    <Root>
      <MobileHeader />
      <BannerContainer>
        <BannerVideo
          src={mainBanner}
          muted={true}
          autoPlay={true}
          loop={true}
          playsInline={true}
        />
        <BannerTypo>GG LEVERAGE</BannerTypo>
        <BannerScrollDown
          src={mainBannerScrollDown}
          onClick={onClickScrollDown}
        />
      </BannerContainer>
      <BannerVideo2Wrapper>
        <BannerVideo2
          src={mainBanner3}
          muted={true}
          autoPlay={true}
          loop={true}
          playsInline={true}
        />
      </BannerVideo2Wrapper>
      <ContentImg src={mainContent} />
      <ContentSharingImg src={mainSharing1} />
      {/* <BannerVideo3Wrapper>
        <BannerVideo3
          src={mainBanner3}
          muted={true}
          autoPlay={true}
          loop={true}
          playsInline={true}
        />
      </BannerVideo3Wrapper> */}
      <MobileFooter />
    </Root>
  );
};

export default MobileMain;
