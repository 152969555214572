import { Modal } from 'antd';
import { commonAxios } from 'api/commonAxios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import MobileFooter from 'systems/Footer/Mobile';
import MobileHeader from 'systems/Header/Mobile';
import MobileParentModal from 'systems/ParentModal/Mobile';
import MobileSubBanner from 'systems/SubBanner/Mobile';
import { numberFormat } from 'utils/format';
import {
  Container,
  ContentContainer,
  IdContainer,
  IdTypo,
  InfoContentTypo,
  InfoHeaderTypo,
  InfoTypoContainer,
  MenuContainer,
  MenuTypo,
  MenuTypoWrapper,
  Root,
  InfoContainer,
  TitleTypo,
  SharingBonusContainer,
  SharingBonusInfoContentcontainer,
  SharingBonusInfoHeaderContainer,
  SharingBonusInfoHeaderTypo,
  SharingBonusInfoTable,
  SharingBonusTable,
  SharingBonusTableDateContainer,
  SharingBonusTableDateTitleTypo,
  SharingBonusTitleTypo,
  SubmitContainer,
  SubmitButton,
  CurrentTable,
  CurrentTableHeaderContainer,
  CurrentTableHeaderTypo,
  CurrentTableContentContainer,
  CurrentTableContentTypo,
  CurrentTableContentButton,
  RentApplyButton,
  RentApplyButtonWrapper,
  RentApplyContainer,
  RentContainer,
  RentModalButton,
  RentModalButtonWrapper,
  RentModalItemBar,
  RentModalItemContainer,
  RentModalItemContentTypo,
  RentModalItemHeaderTypo,
  RentModalRoot,
  RentRecentContainer,
  RentRecentContentContainer,
  RentRecentHeaderContainer,
  RentRecentItem,
  RentRecentItemButton,
  RentRecentTable,
  TableContainer,
  TableHeaderContainer,
  TableHeaderItem,
  TableHeaderItemTitleContainer,
  TableHeaderItemTitleTypo,
  TableHeaderItemContentBarContainer,
  TableHeaderItemContentBar,
  ContentButton,
  InfoTable,
  InfoHeaderContainer,
  InfoItem,
  InfoContentContainer,
} from './styled';

const MobileMyPage = () => {
  const [select, setSelect] = useState<'1' | '2' | '3'>('1');
  const [select2, setSelect2] = useState<number>(0);
  const [userData, setUserData] = useState<any>();
  const [memberData, setMemberData] = useState<any>();
  const [memberData2, setMemberData2] = useState<any>();
  const [rentData, setRentData] = useState<any[]>();
  const [sharingData, setSharingData] = useState<any[]>();
  const [rentDataDetails, setRentDataDetails] = useState<any>();
  const [rentId, setRentId] = useState<number>(0);
  const [parentMemberData, setParentMemberData] = useState<any[]>([]);
  const [openParent, setOpenParent] = useState<boolean>(false);
  const [myId, setMyId] = useState<any>();

  let accessToken = localStorage.getItem('access_token');

  const onClickMemeber = (username: string) => () => {
    commonAxios({
      url: `referrals/${username}`,
      method: 'GET',
      Authorization: `Bearer ${accessToken}`,
    }).then((res) => {
      setParentMemberData(res.data?.referral_nodes);
    });
  };

  useEffect(() => {
    if (accessToken) {
      commonAxios({
        url: 'auth/profile',
        method: 'GET',
        Authorization: `Bearer ${accessToken}`,
      }).then((res) => {
        if (res.data?.user_info) {
          setUserData(res.data.user_info);
          setMemberData(res.data.referral_nodes);
          setMemberData2(res.data?.parent);
          setMyId(res.data?.hierarchy.id);
        }
      });
      commonAxios({
        url: 'rentals/',
        method: 'GET',
        Authorization: `Bearer ${accessToken}`,
      }).then((res) => {
        if (res.data) {
          setRentData(res.data);
        } else {
          alert('에러');
        }
      });
      commonAxios({
        url: 'sharing_bonuses/',
        method: 'GET',
        Authorization: `Bearer ${accessToken}`,
      }).then((res) => {
        if (res.data) {
          setSharingData(res.data);
        }
      });
    } else {
      alert('로그인이 필요한 페이지입니다.');
      window.location.replace('/');
    }
  }, []);

  useEffect(() => {
    commonAxios({
      url: `rentals/${rentId}`,
      method: 'GET',
      Authorization: `Bearer ${accessToken}`,
    }).then((res) => {
      if (res.data) {
        setRentDataDetails(res.data);
      }
    });
  }, [rentId]);

  return (
    <Root>
      <MobileHeader />
      <MobileSubBanner label='마이페이지' imgType='5' />
      <MenuContainer>
        <MenuTypoWrapper onClick={() => setSelect('1')}>
          <MenuTypo isPointed={select === '1'}>본인정보</MenuTypo>
        </MenuTypoWrapper>
        <MenuTypoWrapper onClick={() => setSelect('2')}>
          <MenuTypo isPointed={select === '2'}>쉐어링 보너스 관리</MenuTypo>
        </MenuTypoWrapper>
        <MenuTypoWrapper onClick={() => setSelect('3')}>
          <MenuTypo isPointed={select === '3'}>대여현황</MenuTypo>
        </MenuTypoWrapper>
      </MenuContainer>
      <Container>
        {select === '1' && (
          <>
            <ContentContainer>
              <TitleTypo>아이디</TitleTypo>
              <IdContainer>
                <IdTypo>{userData?.username}</IdTypo>
              </IdContainer>
            </ContentContainer>
            <ContentContainer>
              <TitleTypo>본인정보</TitleTypo>
              <InfoContainer>
                <InfoTypoContainer>
                  <InfoHeaderTypo>성명</InfoHeaderTypo>
                  <InfoContentTypo>{userData?.name}</InfoContentTypo>
                </InfoTypoContainer>
                <InfoTypoContainer>
                  <InfoHeaderTypo>주소</InfoHeaderTypo>
                  <InfoContentTypo>{userData?.address}</InfoContentTypo>
                </InfoTypoContainer>
                <InfoTypoContainer>
                  <InfoHeaderTypo>휴대전화</InfoHeaderTypo>
                  <InfoContentTypo>{userData?.phone_number}</InfoContentTypo>
                </InfoTypoContainer>
                <InfoTypoContainer>
                  <InfoHeaderTypo>이메일</InfoHeaderTypo>
                  <InfoContentTypo>{userData?.email}</InfoContentTypo>
                </InfoTypoContainer>
                <InfoTypoContainer>
                  <InfoHeaderTypo>생일</InfoHeaderTypo>
                  <InfoContentTypo>{userData?.birthday}</InfoContentTypo>
                </InfoTypoContainer>
              </InfoContainer>
            </ContentContainer>
            {/* <TableContainer>
              <TableHeaderContainer>
                <TableHeaderItem isPointed={true}>김동동</TableHeaderItem>
                <TableHeaderItem isPointed={false}>김동동</TableHeaderItem>
              </TableHeaderContainer>
              <TableHeaderItemTitleContainer>
                <TableHeaderItemTitleTypo>타이틀</TableHeaderItemTitleTypo>
                <TableHeaderItemTitleTypo>대여금 합계</TableHeaderItemTitleTypo>
              </TableHeaderItemTitleContainer>
              <TableHeaderItemContentBarContainer>
                <TableHeaderItemContentBar style={{ marginLeft: 10 }} />
                <TableHeaderItemContentBar style={{ marginLeft: 90 }} />
              </TableHeaderItemContentBarContainer>
            </TableContainer> */}

            <InfoTable>
              <InfoHeaderContainer>
                <InfoItem width={333}>성명</InfoItem>
                <InfoItem width={333}>타이틀</InfoItem>
                <InfoItem width={333}>대여금합계</InfoItem>
              </InfoHeaderContainer>
              {memberData2 && (
                <InfoContentContainer>
                  <InfoItem width={333}>{memberData2?.realname}</InfoItem>
                  <InfoItem width={333}>{memberData2?.referral_type}</InfoItem>
                  <InfoItem width={333}>
                    {memberData2?.total_rental_amount &&
                      numberFormat(memberData2?.total_rental_amount)}{' '}
                    원
                  </InfoItem>
                </InfoContentContainer>
              )}
              {memberData &&
                memberData?.map((value: any, index: number) => (
                  <InfoContentContainer>
                    <InfoItem width={333}>{value?.realname}</InfoItem>
                    <InfoItem width={333}>{value?.referral_type}</InfoItem>
                    <InfoItem width={333}>
                      {value?.total_rental_amount &&
                        numberFormat(value?.total_rental_amount)}{' '}
                      원
                    </InfoItem>
                  </InfoContentContainer>
                ))}
            </InfoTable>
            <ContentButton onClick={() => setOpenParent(true)}>
              계보도 보기
            </ContentButton>
          </>
        )}
        {select === '2' && (
          <ContentContainer>
            <SharingBonusContainer>
              <TitleTypo>쉐어링 보너스 관리</TitleTypo>
              <SharingBonusTable>
                <SharingBonusTableDateContainer>
                  <SharingBonusTableDateTitleTypo>
                    모든 정보 보기
                  </SharingBonusTableDateTitleTypo>
                </SharingBonusTableDateContainer>
                <SharingBonusInfoTable>
                  <SharingBonusInfoHeaderContainer>
                    <SharingBonusInfoHeaderTypo>
                      지급월
                    </SharingBonusInfoHeaderTypo>
                    <SharingBonusInfoHeaderTypo>
                      차월 예상지급액(세전)
                    </SharingBonusInfoHeaderTypo>
                    <SharingBonusInfoHeaderTypo>
                      쉐어링 보너스 내역
                    </SharingBonusInfoHeaderTypo>
                  </SharingBonusInfoHeaderContainer>
                  {sharingData?.map((value, index) => {
                    return value.sharing_bonuses.length > 0 ? (
                      <>
                        <SharingBonusInfoContentcontainer>
                          <SharingBonusInfoHeaderTypo>
                            {value.label}
                          </SharingBonusInfoHeaderTypo>
                          {/* <SharingBonusInfoHeaderTypo>
                        {value.total_amount} 원
                      </SharingBonusInfoHeaderTypo> */}
                        </SharingBonusInfoContentcontainer>
                        {value?.sharing_bonuses.map(
                          (value2: any, index2: any) => {
                            return (
                              <SharingBonusInfoHeaderContainer>
                                <SharingBonusInfoHeaderTypo
                                  style={{
                                    justifyContent: 'flex-end',
                                    paddingRight: '15px',
                                  }}
                                >
                                  월간
                                </SharingBonusInfoHeaderTypo>
                                <SharingBonusInfoHeaderTypo>
                                  {value2.amount && numberFormat(value2.amount)}{' '}
                                  원
                                </SharingBonusInfoHeaderTypo>
                                <SharingBonusInfoHeaderTypo
                                  style={{
                                    color: '#008AB0',
                                    cursor: 'pointer',
                                  }}
                                >
                                  상세 내역 조회
                                </SharingBonusInfoHeaderTypo>
                              </SharingBonusInfoHeaderContainer>
                            );
                          }
                        )}
                      </>
                    ) : (
                      <></>
                    );
                  })}
                </SharingBonusInfoTable>
              </SharingBonusTable>
            </SharingBonusContainer>
          </ContentContainer>
        )}
        {select === '3' && (
          <>
            <ContentContainer>
              <TitleTypo>대여신청 </TitleTypo>
              <SubmitContainer>
                <SubmitButton
                  onClick={() => (window.location.href = `/mypage/paper`)}
                >
                  대여신청
                </SubmitButton>
              </SubmitContainer>
            </ContentContainer>

            <ContentContainer>
              <TitleTypo>대여현황 </TitleTypo>
              <CurrentTable>
                <CurrentTableHeaderContainer>
                  <CurrentTableHeaderTypo>대여인</CurrentTableHeaderTypo>
                  <CurrentTableHeaderTypo>대여일</CurrentTableHeaderTypo>
                  <CurrentTableHeaderTypo>현황표</CurrentTableHeaderTypo>
                </CurrentTableHeaderContainer>
                {rentData?.map((value, index) => {
                  return (
                    <CurrentTableContentContainer>
                      <>
                        <CurrentTableContentTypo>
                          {value.username}
                        </CurrentTableContentTypo>
                        <CurrentTableContentTypo>
                          {value.created_at
                            ? moment(value.created_at).format('YYYY.MM.DD')
                            : null}
                        </CurrentTableContentTypo>
                        <CurrentTableContentTypo>
                          <CurrentTableContentButton
                            type='primary'
                            onClick={() => setRentId(value.id)}
                          >
                            현황표 보기
                          </CurrentTableContentButton>
                        </CurrentTableContentTypo>
                      </>
                    </CurrentTableContentContainer>
                  );
                })}
              </CurrentTable>
            </ContentContainer>
          </>
        )}
      </Container>

      <Modal
        visible={rentId !== 0}
        onCancel={() => setRentId(0)}
        footer={null}
        width={580}
        title='현황표'
      >
        <RentModalRoot>
          <RentModalItemContainer>
            <RentModalItemHeaderTypo>대여인</RentModalItemHeaderTypo>
            <RentModalItemContentTypo>
              {rentDataDetails?.username}
            </RentModalItemContentTypo>
          </RentModalItemContainer>
          <RentModalItemContainer>
            <RentModalItemHeaderTypo>주소</RentModalItemHeaderTypo>
            <RentModalItemContentTypo>
              {rentDataDetails?.address}
            </RentModalItemContentTypo>
          </RentModalItemContainer>
          <RentModalItemContainer>
            <RentModalItemHeaderTypo>
              주민등록번호
              <br />
              (사업자등록번호)
            </RentModalItemHeaderTypo>
            <RentModalItemContentTypo>
              {rentDataDetails?.social_security_number}
            </RentModalItemContentTypo>
          </RentModalItemContainer>
          <RentModalItemContainer>
            <RentModalItemHeaderTypo>핸드폰번호</RentModalItemHeaderTypo>
            <RentModalItemContentTypo>
              {rentDataDetails?.phone_number}
            </RentModalItemContentTypo>
          </RentModalItemContainer>
          <RentModalItemBar />
          <RentModalItemContainer>
            <RentModalItemHeaderTypo>차용인</RentModalItemHeaderTypo>
            <RentModalItemContentTypo>
              GG레버리지 남태훈
            </RentModalItemContentTypo>
          </RentModalItemContainer>
          <RentModalItemContainer>
            <RentModalItemHeaderTypo>주소</RentModalItemHeaderTypo>
            <RentModalItemContentTypo>
              서울시 강남구 테헤란로 82길15 1288호(대치동, 디아이타워)
            </RentModalItemContentTypo>
          </RentModalItemContainer>
          <RentModalItemContainer>
            <RentModalItemHeaderTypo>
              주민등록번호
              <br />
              (사업자등록번호)
            </RentModalItemHeaderTypo>
            <RentModalItemContentTypo>894-05-02368</RentModalItemContentTypo>
          </RentModalItemContainer>
          <RentModalItemContainer>
            <RentModalItemHeaderTypo>핸드폰번호</RentModalItemHeaderTypo>
            <RentModalItemContentTypo>010-2886-3505</RentModalItemContentTypo>
          </RentModalItemContainer>
          <RentModalItemBar />
          <RentModalItemContainer>
            <RentModalItemHeaderTypo>대여금액</RentModalItemHeaderTypo>
            <RentModalItemContentTypo>
              {rentDataDetails?.amount && numberFormat(rentDataDetails?.amount)}
              원
            </RentModalItemContentTypo>
          </RentModalItemContainer>
          <RentModalItemContainer>
            <RentModalItemHeaderTypo>대여금이자</RentModalItemHeaderTypo>
            <RentModalItemContentTypo>24%</RentModalItemContentTypo>
          </RentModalItemContainer>
          <RentModalItemContainer>
            <RentModalItemHeaderTypo></RentModalItemHeaderTypo>
            <RentModalItemContentTypo>
              {rentDataDetails?.bank_account_type}
            </RentModalItemContentTypo>
          </RentModalItemContainer>
          <RentModalItemContainer>
            <RentModalItemHeaderTypo></RentModalItemHeaderTypo>
            <RentModalItemContentTypo>
              {rentDataDetails?.bank_account_number}
            </RentModalItemContentTypo>
          </RentModalItemContainer>
          <RentModalItemContainer>
            <RentModalItemHeaderTypo></RentModalItemHeaderTypo>
            <RentModalItemContentTypo>
              {rentDataDetails?.bank_account_owner_name}
            </RentModalItemContentTypo>
          </RentModalItemContainer>
          <RentModalItemBar />
          <RentModalItemContainer>
            <RentModalItemHeaderTypo>변제기일</RentModalItemHeaderTypo>
            <RentModalItemContentTypo>
              {rentDataDetails?.due_date}
            </RentModalItemContentTypo>
          </RentModalItemContainer>
          {/* <RentModalItemContainer>
            <RentModalItemHeaderTypo>변제인 정보</RentModalItemHeaderTypo>
            <RentModalItemContentTypo>-</RentModalItemContentTypo>
          </RentModalItemContainer>
          <RentModalItemContainer>
            <RentModalItemHeaderTypo></RentModalItemHeaderTypo>
            <RentModalItemContentTypo>-</RentModalItemContentTypo>
          </RentModalItemContainer>
          <RentModalItemContainer>
            <RentModalItemHeaderTypo></RentModalItemHeaderTypo>
            <RentModalItemContentTypo>-</RentModalItemContentTypo>
          </RentModalItemContainer> */}
          <RentModalButtonWrapper>
            <RentModalButton onClick={() => setRentId(0)} type='primary'>
              확인
            </RentModalButton>
          </RentModalButtonWrapper>
        </RentModalRoot>
      </Modal>
      <MobileFooter />
      <MobileParentModal
        parent={memberData2}
        childrenList={memberData?.filter(
          (value: any, index: number) => index > 0
        )}
        me={userData}
        visible={openParent}
        onCancel={() => setOpenParent(false)}
        myId={myId}
      />
    </Root>
  );
};

export default MobileMyPage;
