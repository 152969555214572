import { Button, Typography } from 'antd';
import styled from 'styled-components';

export const Root = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-top: 150px;
`;

export const Container = styled.div`
  width: 1000px;
  display: flex;
  flex-direction: column;
  padding-top: 80px;
  padding-bottom: 150px;
`;

export const TitleTypo = styled(Typography)`
  &&& {
    font-family: Pretendard;
    font-size: 24px;
    font-weight: 700;
    color: #333;
  }
`;

export const Bar = styled.div`
  width: 100%;
  height: 1px;
  background: #333;
`;

export const InfoContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 20px;
`;

export const InfoPersonalContainer = styled.div`
  width: 675px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const InfoPersonalInnerContainer = styled.div`
  width: 675px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 30px 60px;
  box-sizing: border-box;
  border: 1px #d9d9d9 solid;
  border-radius: 3px;
`;

export const InfoPersonalInnerLineContainer = styled.div`
  width: 550px;
  display: flex;
  align-items: center;
`;

export const InfoPersonalInnerHeaderTypo = styled(Typography)`
  &&& {
    width: 70px;
    font-family: Pretendard;
    font-size: 16px;
    font-weight: bold;
    color: #555;
  }
`;

export const InfoPersonalInnerContentButton = styled(Button)`
  &&& {
    color: white;
    background: #5135a4;
  }
`;

export const InfoPersonalInnerContentTypo = styled(Typography)`
  &&& {
    width: 180px;
    font-family: Pretendard;
    font-size: 14px;
    color: #555;
  }
`;

export const InfoIdContainer = styled.div`
  width: 305px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const InfoIdInnerContainer = styled.div`
  width: 305px;
  height: 166px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border: 1px #d9d9d9 solid;
  border-radius: 3px;
`;

export const InfoIdInnerTypo = styled(Typography)`
  &&& {
    font-family: Pretendard;
    font-weight: bold;
    font-size: 24px;
    color: #5135a4;
  }
`;

export const InfoTable = styled.div`
  width: 100%;
  border-top: 1px #333 solid;
  margin-top: 30px;
`;

export const InfoHeaderContainer = styled.div`
  width: 100%;
  height: 40px;
  background: #f9f9f9;
  display: flex;
  border-bottom: 1px #e3e3e3 solid;
`;

export const InfoContentContainer = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  border-bottom: 1px #e3e3e3 solid;
`;

type InfoItemProps = {
  width: number;
};

export const InfoItem = styled.div<InfoItemProps>`
  width: ${(props) => props.width}px;
  height: 100%;
  font-family: Pretendard;
  font-size: 16px;
  color: #333;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SharingBonusContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 100px;
`;

export const SharingBonusTitleTypo = styled(Typography)`
  &&& {
    font-family: Pretendard;
    font-size: 24px;
    font-weight: bold;
    color: #333;
  }
`;

export const SharingBonusTable = styled.div`
  width: 100%;
  border-top: 1px #333 solid;
  margin-top: 20px;
`;

export const SharingBonusTableDateContainer = styled.div`
  width: 100%;
  height: 54px;
  display: flex;
  align-items: center;
  padding: 0 15px;
  box-sizing: border-box;
`;

export const SharingBonusTableDateTitleTypo = styled(Typography)`
  &&& {
    font-family: Pretendard;
    font-size: 13px;
    font-weight: bold;
    color: #333;
  }
`;

export const SharingBonusInfoTable = styled.div`
  width: 100%;
  border: 1px #d9d9d9 solid;
  border-radius: 2px;
`;

export const SharingBonusInfoHeaderContainer = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0 200px;
  box-sizing: border-box;
`;

export const SharingBonusInfoHeaderTypo = styled(Typography)`
  &&& {
    width: 210px;
    font-family: Pretendard;
    font-size: 14px;
    color: #333;
  }
`;

export const SharingBonusInfoContentcontainer = styled.div`
  width: 100%;
  height: 40px;
  background: #f9f9f9;
  display: flex;
  align-items: center;
  padding: 0 200px;
  box-sizing: border-box;
  border-top: 1px #e3e3e3 solid;
`;

export const RentContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 20px;
  margin-top: 100px;
`;

export const RentApplyContainer = styled.div`
  width: 305px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const RentApplyButtonWrapper = styled.div`
  width: 305px;
  height: 144px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px #d9d9d9 solid;
  border-radius: 3px;
`;

export const RentApplyButton = styled(Button)`
  width: 223px;
  height: 48px;
  background: #5135a4;
  font-family: Pretendard;
  font-size: 16px;
  font-weight: bold;
`;

export const RentRecentContainer = styled.div`
  width: 673px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const RecentTitleTypo = styled(Typography)`
  &&& {
    font-family: Pretendard;
    font-size: 24px;
    font-weight: bold;
    color: #333;
  }
`;

export const RentRecentTable = styled.div`
  width: 100%;
  border-top: 1px #333 solid;
`;

export const RentRecentHeaderContainer = styled.div`
  width: 100%;
  height: 40px;
  background: #f9f9f9;
  display: flex;
  border-bottom: 1px #e3e3e3 solid;
`;

export const RentRecentContentContainer = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  border-bottom: 1px #e3e3e3 solid;
`;

type RentRecentItemProps = {
  width: number;
};

export const RentRecentItem = styled.div<RentRecentItemProps>`
  width: ${(props) => props.width}px;
  height: 100%;
  font-family: Pretendard;
  font-size: 16px;
  color: #333;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const RentRecentItemButton = styled(Button)`
  width: 96px;
  height: 29px;
  background: #5135a4;
  font-family: Pretendard;
  font-size: 16px;
  font-weight: 500;
  padding: 0;
  border-radius: 2px;
`;

export const RentModalRoot = styled.div`
  padding: 10px;
  box-sizing: border-box;
`;

export const RentModalItemContainer = styled.div`
  width: 506px;
  display: flex;
  align-items: center;
  gap: 30px;
  margin-bottom: 30px;
`;

export const RentModalItemHeaderTypo = styled(Typography)`
  &&& {
    width: 130px;
    font-family: Pretendard;
    font-size: 16px;
    font-weight: bold;
    color: #333;
  }
`;

export const RentModalItemContentTypo = styled(Typography)`
  &&& {
    width: 366px;
    font-family: Pretendard;
    font-size: 14px;
    color: #333;
    padding-bottom: 2px;
    border-bottom: 1px #777 solid;
  }
`;

export const RentModalItemBar = styled.div`
  width: 506px;
  height: 1px;
  background: #e3e3e3;
  margin-top: 40px;
  margin-bottom: 40px;
`;

export const RentModalButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 80px;
`;

export const RentModalButton = styled(Button)`
  width: 146px;
  height: 42px;
  background: #5135a4;
  font-family: Pretendard;
  font-size: 16px;
`;

export const ContentButton = styled(Button)`
  margin-top: 20px;
`;
