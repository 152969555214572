import styled from 'styled-components';

export const Root = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 56px;
`;

export const ContentImgWrapper = styled.div`
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  margin-top: 48px;
`;

export const ContentImg = styled.img`
  width: 100%;
`;
