import { Typography } from 'antd';
import styled from 'styled-components';

export const Root = styled.div`
  width: 100%;
  background: #333;
  display: flex;
  justify-content: center;
  padding: 20px;
`;

export const Container = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const ContentTypo = styled(Typography)`
  font-size: 14px;
  color: white;
`;

export const LogoImg = styled.img`
  width: 40px;
  height: 40px;
`;
