import { Button, Checkbox, Input, Select, Typography } from 'antd';
import styled from 'styled-components';

export const Root = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-top: 56px;
  padding-bottom: 150px;
`;

export const ContentImg = styled.img`
  width: 1062px;
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const SearchBarContainer = styled.div`
  background: #f9f9f9;
  display: flex;
  justify-content: center;
  gap: 10px;
  padding: 30px;
  box-sizing: border-box;
  margin-top: 62px;
`;

export const SearchBarSelect = styled(Select)`
  width: 68px;
  height: 32px;
  .ant-select-selector {
    &&& {
      height: 32px;
    }
  }
`;

export const SearchBarInput = styled(Input)`
  width: 176px;
  height: 32px;
`;

export const SearchBarButton = styled(Button)`
  width: 51px;
  height: 32px;
  background: #5135a4;
  font-family: Pretendard;
  font-size: 14px;
  color: #fff;
`;

export const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 30px;
  box-sizing: border-box;
  margin-top: 70px;
`;

export const ContentTitleTypo = styled(Typography)`
  &&& {
    font-family: Pretendard;
    font-size: 16px;
    font-weight: bold;
    color: #333;
  }
`;

export const ContentTitleBar = styled.div`
  width: 100%;
  height: 1px;
  background: #333;
  margin-top: 12px;
`;

export const InputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 15px;
  margin-top: 50px;
`;

export const ContentInputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 30px;
`;

export const ContentInputTitleTypo = styled(Typography)`
  &&& {
    font-family: Pretendard;
    font-size: 16px;
    font-weight: bold;
    color: #333;
  }
`;

export const ContentInput = styled(Input)`
  &&& {
    font-family: Pretendard;
    font-size: 14px;
    color: #333;
  }
  ::placeholder {
    font-family: Pretendard;
    font-size: 14px;
    color: #333;
  }
`;

export const ContentTextArea = styled(Input.TextArea)`
  &&& {
    font-family: Pretendard;
    font-size: 14px;
    color: #333;
    padding-top: 20px;
    padding-left: 30px;
  }
`;

export const ContentDoubleInputContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 40px;
`;

export const ListButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 40px;
`;

export const ListButton = styled(Button)`
  &&& {
    width: 146px;
    height: 42px;
    background: #5135a4;
    font-family: Pretendard;
    font-size: 16px;
    color: #fff;
  }
`;

export const ContentSelect = styled(Select)`
  &&& {
    .ant-select-selector {
      padding-left: 25px;
    }
  }
`;

export const CheckboxContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: -15px;
`;

export const ContentCheckbox = styled(Checkbox)``;

export const ContentCheckboxTypo = styled(Typography)`
  &&& {
    font-family: Pretendard;
    font-size: 14px;
    font-weight: 500;
    color: #333;
  }
`;
