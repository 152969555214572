import { Button, Input, Select, Typography } from 'antd';
import styled from 'styled-components';

export const Root = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-top: 56px;
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  padding-bottom: 150px;
`;

export const MenuContainer = styled.div`
  width: 100%;
  height: 63px;
  background: #f9f9f9;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-bottom: 1px #d9d9d9 solid;
`;

export const MenuTypoWrapper = styled.div`
  height: 100%;
`;

type MenuTypoProps = {
  isPointed: boolean;
};

export const MenuTypo = styled(Typography)<MenuTypoProps>`
  &&& {
    height: 100%;
    font-family: Pretendard;
    font-size: 14px;
    color: #333;
    border-bottom: 4px #f9f9f9 solid;
    ${(props) =>
      props.isPointed && `font-weight: bold; border-bottom: 4px #333 solid;`}
    display:flex;
    align-items: center;
    cursor: pointer;
  }
`;

export const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 50px;
`;

export const TitleTypo = styled(Typography)`
  &&& {
    font-family: Pretendard;
    font-size: 16px;
    font-weight: bold;
    color: #333;
  }
`;

export const IdContainer = styled.div`
  width: 100%;
  height: 112px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px #d9d9d9 solid;
  border-radius: 2px;
  margin-top: 20px;
`;

export const IdTypo = styled(Typography)`
  &&& {
    font-family: Pretendard;
    font-size: 18px;
    font-weight: bold;
    color: #5135a4;
  }
`;

export const InfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 30px;
  box-sizing: border-box;
  border: 1px #d9d9d9 solid;
  border-radius: 3px;
  margin-top: 20px;
`;

export const InfoTypoContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 30px;
`;

export const InfoHeaderTypo = styled(Typography)`
  &&& {
    width: 80px;
    font-family: Pretendard;
    font-size: 16px;
    font-weight: bold;
    color: #555;
  }
`;

export const InfoContentTypo = styled(Typography)`
  &&& {
    font-family: Pretendard;
    font-size: 14px;
    color: #333;
  }
`;

export const SharingBonusContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const SharingBonusTitleTypo = styled(Typography)`
  &&& {
    font-family: Pretendard;
    font-size: 24px;
    font-weight: bold;
    color: #333;
  }
`;

export const SharingBonusTable = styled.div`
  width: 100%;
  border-top: 1px #333 solid;
  margin-top: 20px;
`;

export const SharingBonusTableDateContainer = styled.div`
  width: 100%;
  height: 54px;
  display: flex;
  align-items: center;
  padding: 0 15px;
  box-sizing: border-box;
`;

export const SharingBonusTableDateTitleTypo = styled(Typography)`
  &&& {
    font-family: Pretendard;
    font-size: 13px;
    font-weight: bold;
    color: #333;
  }
`;

export const SharingBonusInfoTable = styled.div`
  width: 100%;
  border-radius: 2px;
  border-top: 1px #d9d9d9 solid;
`;

export const SharingBonusInfoHeaderContainer = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  box-sizing: border-box;
`;

export const SharingBonusInfoHeaderTypo = styled(Typography)`
  &&& {
    width: 33%;
    font-family: Pretendard;
    font-size: 14px;
    color: #333;
    display: flex;
    justify-content: center;
  }
`;

export const SharingBonusInfoContentcontainer = styled.div`
  width: 100%;
  height: 40px;
  background: #f9f9f9;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0 10px;
  box-sizing: border-box;
  border: 1px #d9d9d9 solid;
`;

export const SubmitContainer = styled.div`
  width: 335px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px #d9d9d9 solid;
  border-radius: 3px;
  margin-top: 20px;
`;

export const SubmitButton = styled(Button)`
  &&& {
    width: 223px;
    height: 48px;
    background: #5135a4;
    color: white;
  }
`;

export const CurrentTable = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-top: 1px #333 solid;
  margin-top: 20px;
`;

export const CurrentTableHeaderContainer = styled.div`
  width: 100%;
  height: 50px;
  background: #f9f9f9;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 0 10px;
  border-bottom: 1px #e3e3e3 solid;
`;

export const CurrentTableHeaderTypo = styled(Typography)`
  &&& {
    width: calc(25% - 5px);
    font-family: Pretendard;
    font-size: 14px;
    color: #333;
    display: flex;
    justify-content: center;
  }
`;

export const CurrentTableContentContainer = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 0 10px;
  border-bottom: 1px #e3e3e3 solid;
`;

export const CurrentTableContentTypo = styled(Typography)`
  &&& {
    width: calc(25% - 5px);
    font-family: Pretendard;
    font-size: 12px;
    color: #333;
    display: flex;
    justify-content: center;
  }
`;

export const CurrentTableContentButton = styled(Button)`
  width: 70px;
  height: 26px;
  font-size: 12px;
  color: #fff;
  background: #5135a4;
  padding: 0;
`;

export const RentContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 20px;
  margin-top: 100px;
`;

export const RentApplyContainer = styled.div`
  width: 305px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const RentApplyButtonWrapper = styled.div`
  width: 305px;
  height: 144px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px #d9d9d9 solid;
  border-radius: 3px;
`;

export const RentApplyButton = styled(Button)`
  width: 223px;
  height: 48px;
  background: #5135a4;
  font-family: Pretendard;
  font-size: 16px;
  font-weight: bold;
`;

export const RentRecentContainer = styled.div`
  width: 673px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const RecentTitleTypo = styled(Typography)`
  &&& {
    font-family: Pretendard;
    font-size: 24px;
    font-weight: bold;
    color: #333;
  }
`;

export const RentRecentTable = styled.div`
  width: 100%;
  border-top: 1px #333 solid;
`;

export const RentRecentHeaderContainer = styled.div`
  width: 100%;
  height: 40px;
  background: #f9f9f9;
  display: flex;
  border-bottom: 1px #e3e3e3 solid;
`;

export const RentRecentContentContainer = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  border-bottom: 1px #e3e3e3 solid;
`;

type RentRecentItemProps = {
  width: number;
};

export const RentRecentItem = styled.div<RentRecentItemProps>`
  width: ${(props) => props.width}px;
  height: 100%;
  font-family: Pretendard;
  font-size: 16px;
  color: #333;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const RentRecentItemButton = styled(Button)`
  width: 96px;
  height: 29px;
  background: #5135a4;
  font-family: Pretendard;
  font-size: 16px;
  font-weight: 500;
  padding: 0;
  border-radius: 2px;
`;

export const RentModalRoot = styled.div`
  padding: 10px;
  box-sizing: border-box;
`;

export const RentModalItemContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  margin-bottom: 30px;
`;

export const RentModalItemHeaderTypo = styled(Typography)`
  &&& {
    width: 96px;
    font-family: Pretendard;
    font-size: 16px;
    font-weight: bold;
    color: #333;
  }
`;

export const RentModalItemContentTypo = styled(Typography)`
  &&& {
    width: 183px;
    font-family: Pretendard;
    font-size: 14px;
    color: #333;
    padding-bottom: 2px;
    border-bottom: 1px #777 solid;
  }
`;

export const RentModalItemBar = styled.div`
  width: 506px;
  height: 1px;
  background: #e3e3e3;
  margin-top: 40px;
  margin-bottom: 40px;
`;

export const RentModalButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 80px;
`;

export const RentModalButton = styled(Button)`
  width: 146px;
  height: 42px;
  background: #5135a4;
  font-family: Pretendard;
  font-size: 16px;
`;

export const TableContainer = styled.div`
  width: 335px;
  height: 141px;
  border: 1px #d9d9d9 solid;
  border-radius: 4px;
  margin-top: 20px;
  overflow: hidden;
`;

export const TableHeaderContainer = styled.div`
  width: 100%;
  height: 40px;
  background: #f9f9f9;
  display: flex;
  align-items: center;
  overflow-x: scroll;
`;

type TableHeaderItemProps = {
  isPointed: boolean;
};

export const TableHeaderItem = styled.div<TableHeaderItemProps>`
  width: 103px;
  height: 40px;
  background: #f9f9f9;
  ${(props) => props.isPointed && `background: #fff;`}
  font-family: Pretendard;
  font-size: 14px;
  font-weight: 500;
  color: #333;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 8px 8px 0 0;
`;

export const TableHeaderItemTitleContainer = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
`;

export const TableHeaderItemTitleTypo = styled(Typography)`
  &&& {
    font-family: Pretendard;
    font-size: 14px;
    font-weight: 500;
    color: #333;
  }
`;

export const TableHeaderItemContentTypo = styled(Typography)`
  &&& {
    font-family: Pretendard;
    font-size: 13px;
    color: #333;
  }
`;

export const TableHeaderItemContentBarContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 95px;
`;

export const TableHeaderItemContentBar = styled.div`
  width: 10px;
  height: 1px;
  background: #d9d9d9;
`;

export const ContentButton = styled(Button)`
  &&& {
    margin-top: 20px;
  }
`;
export const InfoPersonalInnerHeaderTypo = styled(Typography)`
  &&& {
    width: 70px;
    font-family: Pretendard;
    font-size: 16px;
    font-weight: bold;
    color: #555;
  }
`;

export const InfoPersonalInnerContentButton = styled(Button)`
  &&& {
    color: white;
    background: #5135a4;
  }
`;

export const InfoPersonalInnerContentTypo = styled(Typography)`
  &&& {
    width: 180px;
    font-family: Pretendard;
    font-size: 12px;
    color: #555;
  }
`;

export const InfoIdContainer = styled.div`
  width: 305px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const InfoIdInnerContainer = styled.div`
  width: 305px;
  height: 166px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border: 1px #d9d9d9 solid;
  border-radius: 3px;
`;

export const InfoIdInnerTypo = styled(Typography)`
  &&& {
    font-family: Pretendard;
    font-weight: bold;
    font-size: 12px;
    color: #5135a4;
  }
`;

export const InfoTable = styled.div`
  width: 100%;
  border-top: 1px #333 solid;
  margin-top: 30px;
`;

export const InfoHeaderContainer = styled.div`
  width: 100%;
  height: 40px;
  background: #f9f9f9;
  display: flex;
  border-bottom: 1px #e3e3e3 solid;
`;

export const InfoContentContainer = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  border-bottom: 1px #e3e3e3 solid;
`;

type InfoItemProps = {
  width: number;
};

export const InfoItem = styled.div<InfoItemProps>`
  width: ${(props) => props.width}px;
  height: 100%;
  font-family: Pretendard;
  font-size: 12px;
  color: #333;
  display: flex;
  justify-content: center;
  align-items: center;
`;
