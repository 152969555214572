import React, { useEffect, useState } from 'react';
import Header from 'systems/Header';
import {
  Container,
  ContentCollapse,
  ContentCollapseArrowDown,
  ContentCollapseContainer,
  ContentCollapseQ,
  ContentCollapseTypo,
  ContentCollapseTypoContainer,
  ContentContainer,
  ContentPanel,
  ContentPanelTypo,
  Root,
  SearchBarButton,
  SearchBarContainer,
  SearchBarInput,
  SearchBarSelect,
  TableContainer,
  TableContentContainer,
  TableContentTypo,
  TableHeaderContainer,
  TableHeaderTypo,
} from './styled';
import SubBanner from 'systems/SubBanner';
import NoticeNavigator from 'systems/NoticeNavigator';
import { commonAxios } from 'api/commonAxios';
import { ContentInput } from 'pages/Login/styled';
import { isMobile } from 'react-device-detect';
import NoticeSearchBar from 'systems/NoticeSearchBar';
import arrowBottom from 'assets/images/arrow_bottom.png';
import qImg from 'assets/images/Q.png';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import MobileNoticeSearchBar from 'systems/NoticeSearchBar/Mobile';
import MobileNoticeNavigator from 'systems/NoticeNavigator/Mobile';
import MobileSubBanner from 'systems/SubBanner/Mobile';
import MobileHeader from 'systems/Header/Mobile';
import MobileFooter from 'systems/Footer/Mobile';

type NoticeType2 = {
  id: number;
  title: string;
  content: string;
  created_at: string;
  viewed_count: number;
};

type NoticeType = {
  title: string;
  content: string;
  created_at: string;
};

const MobileSearch = () => {
  const [dataList, setDataList] = useState<NoticeType2[]>();
  const [dataList2, setDataList2] = useState<NoticeType[]>();
  const [dataList3, setDataList3] = useState<NoticeType[]>();
  const location = useLocation();
  const select = location.pathname.split('/')[2];
  const value = location.pathname.split('/')[3];

  useEffect(() => {
    commonAxios({
      url: 'notices/',
      method: 'GET',
      params: { search: decodeURIComponent(value) },
    }).then((res) => {
      setDataList(res.data);
    });
    commonAxios({
      url: 'faqs/',
      method: 'GET',
      params: { search: decodeURIComponent(value) },
    }).then((res) => {
      setDataList2(res.data);
    });
  }, []);

  const onClickTable = (id: number) => () => {
    window.location.href = `/notice/details/${id}`;
  };

  return (
    <Root>
      <MobileHeader />
      <MobileSubBanner label='공지사항' imgType='3' />
      <MobileNoticeNavigator type='5' />
      <Container>
        <MobileNoticeSearchBar />
        {(select === '1' || select === '2') && (
          <TableContainer>
            <TableHeaderContainer></TableHeaderContainer>
            {dataList?.map((value, index) => (
              <TableContentContainer
                onClick={onClickTable(value.id)}
                key={`table_content_container_${index}`}
              >
                <TableHeaderTypo>{value.title}</TableHeaderTypo>
                <TableContentTypo>
                  {value.id} | 조회수 {value.viewed_count} | 작성일{' '}
                  {moment(value.created_at).format('YYYY.MM.DD')}
                </TableContentTypo>
              </TableContentContainer>
            ))}
          </TableContainer>
        )}
        {(select === '1' || select === '3') && (
          <ContentContainer>
            <ContentCollapse ghost bordered>
              {dataList2?.map((value, index) => (
                <ContentPanel
                  showArrow={false}
                  header={
                    <ContentCollapseContainer>
                      <ContentCollapseTypoContainer>
                        <ContentCollapseQ src={qImg} />
                        <ContentCollapseTypo>{value.title}</ContentCollapseTypo>
                      </ContentCollapseTypoContainer>
                      <ContentCollapseArrowDown src={arrowBottom} />
                    </ContentCollapseContainer>
                  }
                  key={`${index}`}
                >
                  <ContentPanelTypo>{value.content}</ContentPanelTypo>
                </ContentPanel>
              ))}
            </ContentCollapse>
          </ContentContainer>
        )}
      </Container>
      <MobileFooter />
    </Root>
  );
};

export default MobileSearch;
