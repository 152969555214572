import { Typography } from 'antd';
import styled from 'styled-components';

export const Root = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  overflow: hidden;
`;

export const BannerImg = styled.img`
  height: 92px;
  z-index: -1;
`;

export const TitleTypo = styled(Typography)`
  white-space: nowrap;
  font-family: Pretendard;
  font-size: 28px;
  font-weight: bold;
  color: white;
  position: absolute;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
`;
