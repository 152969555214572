export const bankList = [
  '카카오뱅크',
  '국민은행',
  '기업은행',
  '농협은행',
  '신한은행',
  '산업은행',
  '우리은행',
  '한국씨티은행',
  '하나은행',
  'SC제일은행',
  '경남은행',
  '광주은행',
  '대구은행',
  '부산은행',
  '새마을금고',
  '수협은행',
  '신협중앙회',
  '우체국',
  '전북은행',
  '제주은행',
];
