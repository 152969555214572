import { Button, Collapse, Input, Select, Typography } from 'antd';
import styled from 'styled-components';

export const Root = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-top: 56px;
  padding-bottom: 150px;
`;

export const BannerImg = styled.img`
  width: 1920px;
  height: 170px;
`;

export const ContentImg = styled.img`
  width: 1062px;
`;

export const Container = styled.div`
  width: 315px;
  display: flex;
  flex-direction: column;
`;

export const SearchBarContainer = styled.div`
  background: #f9f9f9;
  display: flex;
  justify-content: center;
  gap: 10px;
  padding: 30px;
  box-sizing: border-box;
  margin-top: 62px;
`;

export const SearchBarSelect = styled(Select)`
  width: 115px;
  height: 32px;
  .ant-select-selector {
    &&& {
      height: 32px;
    }
  }
`;

export const SearchBarInput = styled(Input)`
  width: 376px;
  height: 32px;
`;

export const SearchBarButton = styled(Button)`
  width: 76px;
  height: 32px;
  background: #5135a4;
  font-family: Pretendard;
  font-size: 14px;
  color: #fff;
`;

export const ContentContainer = styled.div`
  width: 315px;
  display: flex;
  flex-direction: column;
  margin-top: 80px;
`;

export const ContentCollapse = styled(Collapse)`
  &&& {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
`;

export const ContentCollapseContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ContentCollapseTypoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

export const ContentCollapseQ = styled.img`
  &&& {
    width: 15px;
    height: 20px;
  }
`;

export const ContentCollapseTypo = styled(Typography)`
  &&& {
    font-family: Pretendard;
    font-size: 16px;
    font-weight: bold;
    color: #333;
  }
`;

export const ContentCollapseArrowDown = styled.img`
  width: 19px;
  height: 7px;
`;

export const ContentPanel = styled(Collapse.Panel)`
  &&& {
    box-shadow: 2px 2px 16px rgba(119, 119, 119, 0.25);
    padding: 24px 18px;
    border-radius: 20px;
  }
`;

export const ContentPanelTypo = styled(Typography)`
  &&& {
    font-family: Pretendard;
    font-size: 14px;
    color: #333;
    margin-top: 20px;
  }
`;
