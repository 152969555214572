import React from 'react';
import Header from 'systems/Header';
import SubBanner from 'systems/SubBanner';
import { ContentContainer, ContentImg, Root } from './styled';
import loan_content from 'assets/images/loan_content.png';
import { isMobile } from 'react-device-detect';
import MobileTerms from './Mobile/index';
import Footer from 'systems/Footer';

const Terms = () => {
  if (isMobile) {
    return <MobileTerms />;
  }
  return (
    <Root>
      <Header />
      <SubBanner imgType='2' label='개인정보처리방침' />
      <ContentContainer>
        {'\b'} GG 레버리지(이하 “회사”)는 회원 여러분의 개인정보를 소중하게
        생각하며 「개인정보보호법」 등 개인정보 보호 법령을 준수하고자 노력하고
        있습니다. 회사는 개인정보처리방침을 통하여 회원 여러분이 제공하는
        개인정보가 어떠한 용도와 방식으로 이용되고 있으며, 개인정보보호를 위하여
        어떠한 조치가 취해지고 있는지 알려 드립니다. 회사의 개인정보처리방침은
        다음과 같은 내용을 담고 있습니다.    <br />
         <br />
        제1조(개인정보 처리목적)  <br />
        제2조(개인정보의 처리 및 보유기간)  <br />
        제3조(개인정보 수집・이용 항목)  <br />
        제4조(개인정보의 제3자 제공)  <br />
        제5조(정보주체의 권리・의무 및 행사방법)  <br />
        제6조(개인정보의 파기)  <br />
        제7조(개인정보 자동 수집 장치의 설치・운영 및 거부에 관한 사항)  <br />
        제08조(아동의 개인정보보호)  <br />
        제09조(개인정보 보호책임자)  <br />
        제10조(권익침해 구제방법)    <br />
         <br />
        <h3> 제1조(개인정보 처리목적)</h3>  <br />
          ① 회사는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는
        개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이
        변경되는 경우에는 개인정보 보호법에 따라 별도의 동의를 받는 등 필요한
        조치를 이행할 예정입니다.    <br />
         <br />
        <ol>
          <li>
            {' '}
            홈페이지 회원 가입 및 관리 : 회원 가입의시 확인, 회원제 서비스
            제공에 따른 본인 식별・인증, 회원자격 유지・관리, 본인확인, 서비스
            부정이용 방지, 미성년자 서비스 제한, 각종 고지・통지, 고충처리 등을
            목적으로 개인정보를 처리합니다.  {' '}
          </li>
          <li>
            재화 또는 서비스 제공 : 서비스 제공, 계약서, 콘텐츠 제공, 맞춤서비스
            제공,연령인증・정산, 후원수당 정산, 인정(행사, SNS,  디스플레이 등),
            신규 서비스 제공 및 사이트 연동 등을 목적으로 개인정보를 처리합니다.
               
          </li>
          <li>
            고충처리 : 민원인의 신원 확인, 민원사항 확인, 사실조사를 위한
            연락・통지, 처리결과 통보 등의 목적으로 개인정보를 처리합니다.  
          </li>
          <li> 프로모션, 이벤트 등 광고성 정보전달    </li>
        </ol>
         <br />
        <h3>제2조(개인정보의 처리 및 보유기간)</h3>    <br />
        ① 회사는 법령에 따른 개인정보 보유・이용기간 또는 정보주체로부터
        개인정보를 수집시에 동의받은 개인정보 보유・이용기간 내에서 개인정보를
        처리・보유합니다.
        <br />② 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.            
           <br />
        &nbsp;&nbsp;&nbsp;&nbsp; (1) 회원 가입 및 서비스 이용 : 회원 탈퇴시까지
         <br />
        &nbsp;&nbsp;&nbsp;&nbsp; (2) 「소득세법」후원수당 발생에 따른 소득신고 :
        5년  <br />
        &nbsp;&nbsp;&nbsp;&nbsp; (3) 「회사 내부 방침」에 의한 정보보유 사유  
        <br />
         <br />
        <h3> 제3조(개인정보 수집・이용 항목)</h3>    <br />
        ① 회사는 다음의 개인정보 항목을 처리하고 있습니다.  <br />
        &nbsp;&nbsp;&nbsp;&nbsp;1. 홈페이지 회원 가입 및 관리  <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(1) 멤버 : 성명,
        생년월일,성별,비밀번호, 주소,휴대폰번호, 전화번호, 이메일, 후원자정보,
        은행계좌정보  <br />
        &nbsp;&nbsp;&nbsp;&nbsp; 2. 재화 또는 서비스 제공  <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(1) 필수항목 : 성명,
        생년월일,비밀번호, 주소, 휴대폰번호, 이메일 주소,  
        <br />
         <br />
        <h3> 제4조(개인정보의 제3자 제공) </h3>   <br />
        ① 회사는 정보주체의 개인정보를 제1조(개인정보의 처리 목적)에서 명시한
        범위 내에서만 처리하며, 정보주체의 동의, 법률의 특별한 규정 등 개인정보
        보호법에 해당하는 경우(법령 규정이 있거나, 수사기관의 목적으로 법적
        절차를 준수하는 경우 등)에만 개인정보를 제3자에게 제공합니다.
        <br />② 회사는 다음과 같이 회원의 동의하에서 아래와 같이 제3자에게
        개인정보를 제공하고 있습니다.  <br />
        <br /> 제공받는 자 - 후원자 / 하위판매원 또는 멤버 등록자 중
        후원자정보를 요청하는 자  <br />
        제공받는 자의 개인정보 이용 목적 - 비지니스 운영(조직관리, 상담) 및 자신
        또는 타인의 회원가입 시 필요한 후원자 정보 획득 및 제공 등  <br />
        제공하는 개인정보의 항목 - 성명, 주소, 전화번호, 휴대폰번호, 생년월일,
        이메일 주소, 비즈니스 활동 정보  <br />
        개인정보 보유・이용 기간 - 제공 동의일로부터 회원 탈퇴 시 까지 보관    
        <br />
         <br />
        <h3> 제5조(정보주체의 권리・의무 및 행사방법)</h3>    <br />
        ① 회사에 대해 언제든지 다음 각 호의 개인정보 보호관련 권리를 행사할 수
        있습니다.    <br />
         <br />
        &nbsp;&nbsp;&nbsp;&nbsp; 1. 개인정보 열람요구    <br />
        &nbsp;&nbsp;&nbsp;&nbsp; 2. 오류 등이 있을 경우 정정 요구    <br />
        &nbsp;&nbsp;&nbsp;&nbsp; 3. 삭제요구    <br />
         &nbsp;&nbsp;&nbsp;&nbsp; 4. 처리정지 요구    <br />
         <br />
        ② 제1항에 따른 권리 행사는 회사에 대해 서면,전화 전자우편등을 통하여
        하실 수 있으며 회사는 이에 권리 행사자 본인 확인후 대해 지체 없이
        조치하겠습니다.    <br />
        ③ 정보주체가 개인정보의 오류등에 대한 정정 또는 삭제를 요구한 경우에는
        회사는 정정 또는 삭제완료 할 때 까지 당해 개인정보를 이용하거나 제공하지
        않습니다.    <br />
        ④ 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등
        대리인을 통하여 하실 수 있습니다. 이 경우 대리인은 개인정보 처리 방법에
        관한 개인정보보호위원회 고시 별지 11.에 따른 위임장을 권리 행사 서면
        등과 함께 제출하셔야 합니다.    <br />
        ⑤ 정보주체는 개인정보 보호법 등 관계법령을 위반하여 회사가 처리하고 있는
        본인이나 타인의 개인정보 및 사생활을 침해하여서는 아니됩니다.    <br /> 
        <br />
        <h3> 제6조(개인정보의 파기)</h3>    <br />
        ① 회사는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가
        불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.  <br />②
        정보주체로부터 동의 받은 개인정보 보유기간이 경과하거나 처리목적이
        달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는
        경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관 장소를
        달리하여 보존합니다.  <br />③ 개인정보 파기의 절차 및 방법은 다음과
        같습니다.  <br />
        1. 파기절차  <br />
        &nbsp;&nbsp;&nbsp;&nbsp;- 회사는 파기 사유가 발생한 개인정보를 선정하고,
        회사의 개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다.  <br />
        2. 파기방법  <br />
        &nbsp;&nbsp;&nbsp;&nbsp;- 종이에 출력된 개인정보 : 분쇄기로 분쇄하거나
        소각  <br />
        &nbsp;&nbsp;&nbsp;&nbsp;- 전자적 파일 형태로 저장된 개인정보 : 기록을
        재생할 수 없는 기술적 방법을 사용하여 삭제    <br />
         <br />
        <h3>
          제7조(개인정보 자동 수집 장치의 설치・운영 및 거부에 관한 사항){' '}
        </h3>
             <br />
        ① 회사는 정보주체에게 개별적인 맞춤서비스를 제공하기 위해 이용정보를
        저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용하지 않습니다. ② 회사는
        개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.    
        <br />
         <br />
        1. 관리적 조치 : 내부관리계획 수립․시행, 정기적 직원 교육 등  <br />
        2. 기술적 조치 : 개인정보처리시스템 등의 접근권한 관리, 접근통제시스템
        설치, 고유식별정보 등의 암호화, 보안프로그램 설치    <br />
        <br />
        <h3> 제08조(아동의 개인정보보호) </h3> <br />
          ① 회사는 만 19이상만 회원가입이 가능합니다.  <br />
        <br />
        <h3>제09조(개인정보보호 책임자)</h3>  <br />
        ① 회사는 개인정보처리에 관한 업무를 총괄해서 책임지고, 개인정보처리와
        관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이
        개인정보보호 책임자를 지정하고 있습니다.  <br />
         <br />
        ▶ 개인정보보호 책임자  <br />
        성명 : 남태훈  <br />
        직책 : 최고 정보 책임자 (CIO)  <br />
        직급 : 대표  <br />
        메일 : namga2020@naver.com  <br />
        휴대전화 : 010-2886-3505  <br />
           <br />
        <h3> 제10조(권익침해 구제방법)</h3>      <br />
        ① 정보주체는 아래의 기관에 대해 개인정보 침해에 대한 피해구제, 상담 등을
        문의하실 수 있습니다.  <br />
              ▶ 개인정보침해신고센터 : (국번없이) 118
        (https://privacy.kisa.or.kr)  <br />
              ▶개인정보분쟁조정위원회:(국번없이)1833-697
        (https://www.kopico.go.kr)  <br />
              ▶경찰청사이버 안전국 : (국번없이) 182
        (http://cyberbureau.police.go.kr)  <br />      ▶ 대검찰청
        사이버범죄수사단 : 02-3480-3571 (http://www.spo.go.kr)
      </ContentContainer>
      <Footer />
    </Root>
  );
};

export default Terms;
