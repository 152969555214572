import React, { useEffect, useState } from 'react';
import Header from 'systems/Header';
import {
  CheckboxContainer,
  Container,
  ContentCheckbox,
  ContentCheckboxTypo,
  ContentContainer,
  ContentDoubleInputContainer,
  ContentInputContainer,
  ContentInputTitleTypo,
  ContentSelect,
  ContentTextArea,
  ContentTitleBar,
  ContentTitleTypo,
  InputContainer,
  ListButton,
  ListButtonContainer,
  Root,
  SearchBarButton,
  SearchBarContainer,
  SearchBarInput,
  SearchBarSelect,
} from './styled';
import SubBanner from 'systems/SubBanner';
import NoticeNavigator from 'systems/NoticeNavigator';
import { commonAxios } from 'api/commonAxios';
import { ContentInput } from 'pages/Login/styled';
import { isMobile } from 'react-device-detect';
import MobileQuestion from './Mobile';
import NoticeSearchBar from 'systems/NoticeSearchBar';
import { message } from 'antd';
import Footer from 'systems/Footer';

type NoticeType = {
  title: string;
  content: string;
  created_at: string;
};

const Question = () => {
  let accessToken = localStorage.getItem('access_token');
  const [userNumber, setUserNumber] = useState<number>(0);
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [type, setType] = useState<string>('default');
  const [title, setTitle] = useState<string>('');
  const [content, setContent] = useState<string>('');
  const [agree, setAgree] = useState<boolean>(false);

  const onClickSubmit = () => {
    if (!agree) {
      message.error('개인정보 처리방침에 동의해주세요.');
      return;
    }
    commonAxios({
      url: 'questions/create',
      method: 'POST',
      Authorization: `Bearer ${accessToken}`,
      data: {
        userid: userNumber,
        username: name,
        name,
        email,
        phone_number: phoneNumber,
        question_type: type,
        title,
        content,
      },
    }).then((res) => {
      alert('문의가 등록되었습니다. 빠른 시일내에 답변드리겠습니다.');
      window.location.reload();
    });
  };

  if (isMobile) {
    return <MobileQuestion />;
  }

  return (
    <Root>
      <Header />
      <SubBanner label='공지사항' imgType='3' />
      <NoticeNavigator type='3' />
      <Container>
        <NoticeSearchBar />
        <ContentContainer>
          <ContentTitleTypo>
            문의사항을 남겨주시면 신속히 답변드리겠습니다.
          </ContentTitleTypo>
          <ContentTitleBar />
          <InputContainer>
            {/* <ContentInputContainer>
              <ContentInputTitleTypo>회원번호</ContentInputTitleTypo>
              <ContentInput
                value={userNumber}
                onChange={(e) => setUserNumber(e.target.value)}
                size='large'
                placeholder='회원정보 입력'
              />
            </ContentInputContainer> */}
            <ContentDoubleInputContainer>
              <ContentInputContainer>
                <ContentInputTitleTypo>이름</ContentInputTitleTypo>
                <ContentInput
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  size='large'
                  placeholder='이름 입력'
                />
              </ContentInputContainer>
              <ContentInputContainer>
                <ContentInputTitleTypo>이메일</ContentInputTitleTypo>
                <ContentInput
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  size='large'
                  placeholder='이메일 입력'
                />
              </ContentInputContainer>
            </ContentDoubleInputContainer>
            <ContentDoubleInputContainer>
              <ContentInputContainer>
                <ContentInputTitleTypo>연락처</ContentInputTitleTypo>
                <ContentInput
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  size='large'
                  placeholder='연락처 입력'
                />
              </ContentInputContainer>
            </ContentDoubleInputContainer>
            <ContentInputContainer>
              <ContentInputTitleTypo>문의제목</ContentInputTitleTypo>
              <ContentInput
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                size='large'
                placeholder='문의제목 입력'
              />
            </ContentInputContainer>
            <ContentInputContainer>
              <ContentInputTitleTypo>문의내용</ContentInputTitleTypo>
              <ContentTextArea
                rows={10}
                size='large'
                placeholder='문의내용 입력'
                value={content}
                onChange={(e) => setContent(e.target.value)}
              />
            </ContentInputContainer>
            <CheckboxContainer>
              <ContentCheckbox
                checked={agree}
                onClick={(e) => setAgree((prev) => !prev)}
              />
              <ContentCheckboxTypo>
                개인정보 처리방침 및 동의하기
              </ContentCheckboxTypo>
            </CheckboxContainer>
          </InputContainer>
        </ContentContainer>
        <ListButtonContainer>
          <ListButton onClick={onClickSubmit}>전송하기</ListButton>
        </ListButtonContainer>
      </Container>
      <Footer />
    </Root>
  );
};

export default Question;
