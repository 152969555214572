import React, { useEffect, useState } from 'react';
import {
  BackgroundImg,
  Container,
  ContentButton,
  ContentContainer,
  ContentInput,
  ContentInputContainer,
  ContentMenuBar,
  ContentMenuContainer,
  ContentMenuTypo,
  ContentMenuTypoWrapper,
  ContentSubmitButton,
  ContentSubmitButtonTypo,
  ContentTitleTypo,
  Root,
} from './styled';
import Header from 'systems/Header';
import { commonAxios } from 'api/commonAxios';
import { isMobile } from 'react-device-detect';
import MobileLogin from './Mobile';
import { Input, message, Modal } from 'antd';
import loginBanner from 'assets/images/login_banner.png';
import Footer from 'systems/Footer';

const Login = () => {
  const [login, setLogin] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [openFindId, setOpenFindId] = useState<boolean>(false);
  const [openFindPassword, setOpenFindPassword] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [username, setUsername] = useState<string>('');

  const onChangeInput = (type: 'LOGIN' | 'PASSWORD') => (e: any) => {
    if (type === 'LOGIN') {
      setLogin(e.target.value);
      return;
    }
    setPassword(e.target.value);
    return;
  };

  const onClickSubmit = () => {
    commonAxios({
      url: 'auth/login',
      method: 'POST',
      data: {
        username: login,
        password,
      },
    })
      .then((res) => {
        if (res.data?.access_token) {
          localStorage.setItem('access_token', res.data.access_token);
          window.location.replace('/');
        } else {
          alert('아이디와 비밀번호를 확인해주세요.');
        }
      })
      .catch(() => {
        alert('아이디와 비밀번호를 확인해주세요.');
      });
  };

  const onKeyPress = (e: any) => {
    if (e.key === 'Enter') {
      onClickSubmit();
    }
  };

  const onClickFindId = () => {
    setOpenFindId(false);
    setEmail('');
    commonAxios({
      url: 'auth/find_username',
      method: 'POST',
      data: { email },
    }).then((res) => {
      if (res.data?.username) {
        alert(`아이디 : ${res.data?.username}`);
      }
    });
  };

  const onClickFindPassword = () => {
    setOpenFindPassword(false);
    setEmail('');
    setUsername('');
    commonAxios({
      url: 'auth/find_password',
      method: 'POST',
      data: { email, username },
    }).then((res) => {
      if (res.data?.password) {
        alert(`임시 비밀번호 : ${res.data?.password}`);
      }
    });
  };

  useEffect(() => {
    window.localStorage.removeItem('access_token');
  }, []);

  if (isMobile) {
    return <MobileLogin />;
  }

  return (
    <Root>
      <Header />
      <Container>
        <BackgroundImg src={loginBanner} />
        <ContentContainer>
          <ContentTitleTypo>로그인</ContentTitleTypo>
          <ContentInputContainer>
            <ContentInput
              value={login}
              onChange={onChangeInput('LOGIN')}
              size='large'
              placeholder='아이디 입력'
              onKeyDown={onKeyPress}
            />
            <ContentInput
              type='password'
              value={password}
              onChange={onChangeInput('PASSWORD')}
              size='large'
              placeholder='비밀번호 입력'
              onKeyDown={onKeyPress}
            />
          </ContentInputContainer>
          <ContentSubmitButton type='primary' onClick={onClickSubmit}>
            <ContentSubmitButtonTypo>로그인</ContentSubmitButtonTypo>
          </ContentSubmitButton>
          <ContentMenuContainer>
            <ContentMenuTypoWrapper onClick={() => setOpenFindId(true)}>
              <ContentMenuTypo>아이디 찾기</ContentMenuTypo>
            </ContentMenuTypoWrapper>
            <ContentMenuBar />
            <ContentMenuTypoWrapper onClick={() => setOpenFindPassword(true)}>
              <ContentMenuTypo>비밀번호 찾기</ContentMenuTypo>
            </ContentMenuTypoWrapper>
            <ContentMenuBar />
            <ContentMenuTypoWrapper
              onClick={() => (window.location.href = `/join`)}
            >
              <ContentMenuTypo>회원가입</ContentMenuTypo>
            </ContentMenuTypoWrapper>
          </ContentMenuContainer>
        </ContentContainer>
      </Container>
      <Modal
        footer={null}
        visible={openFindId}
        onCancel={() => setOpenFindId(false)}
        title='아이디 찾기'
      >
        <Input
          placeholder='이메일을 입력하세요.'
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <ContentButton type='primary' onClick={onClickFindId}>
          아이디 찾기
        </ContentButton>
      </Modal>
      <Modal
        footer={null}
        visible={openFindPassword}
        onCancel={() => setOpenFindPassword(false)}
        title='비밀번호 찾기'
      >
        <Input
          placeholder='이메일을 입력하세요.'
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Input
          style={{ marginTop: 10 }}
          placeholder='아이디를 입력하세요.'
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <ContentButton type='primary' onClick={onClickFindPassword}>
          비밀번호 찾기
        </ContentButton>
      </Modal>
      <Footer />
    </Root>
  );
};

export default Login;
