import { Button, Input, Select } from 'antd';
import styled from 'styled-components';

export const SearchBarContainer = styled.div`
  background: #f9f9f9;
  display: flex;
  justify-content: center;
  gap: 10px;
  padding: 30px;
  box-sizing: border-box;
  margin-top: 62px;
`;

export const SearchBarSelect = styled(Select)`
  width: 115px;
  height: 40px;
  .ant-select-selector {
    &&& {
      height: 40px;
    }
  }
`;

export const SearchBarInput = styled(Input)`
  width: 376px;
  height: 40px;
`;

export const SearchBarButton = styled(Button)`
  width: 76px;
  height: 40px;
  background: #5135a4;
  font-family: Pretendard;
  font-size: 14px;
  color: #fff;
`;
