import React, { useEffect, useState } from 'react';
import Header from 'systems/Header';
import {
  Container,
  Root,
  SearchBarButton,
  SearchBarContainer,
  SearchBarInput,
  SearchBarSelect,
} from './styled';
import SubBanner from 'systems/SubBanner';
import NoticeNavigator from 'systems/NoticeNavigator';
import { commonAxios } from 'api/commonAxios';

type NoticeType = {
  title: string;
  content: string;
  created_at: string;
};

const Board = () => {
  const [dataList, setDataList] = useState<NoticeType[]>();

  useEffect(() => {
    commonAxios({ url: 'notices/', method: 'GET' }).then((res) => {
      setDataList(res.data);
    });
  }, []);

  const onClickTable = (id: number) => () => {
    window.location.href = `/notice/details/${id}`;
  };

  return (
    <Root>
      <Header />
      <SubBanner label='공지사항' imgType='3' />
      <NoticeNavigator type='5' />
      <Container>
        <SearchBarContainer>
          <SearchBarSelect size='large' value={1}>
            <SearchBarSelect.Option value={1}>전체</SearchBarSelect.Option>
          </SearchBarSelect>
          <SearchBarInput />
          <SearchBarButton>검색</SearchBarButton>
        </SearchBarContainer>
      </Container>
    </Root>
  );
};

export default Board;
