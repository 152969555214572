import React from 'react';
import { BannerImg, Root, TitleTypo } from './styled';
import sub_banner from 'assets/images/sub_banner.png';
import sub_banner_2 from 'assets/images/sub_banner_2.png';
import sub_banner_3 from 'assets/images/sub_banner_3.png';
import sub_banner_4 from 'assets/images/sub_banner_4.png';
import sub_banner_5 from 'assets/images/sub_banner_5.png';

type Props = {
  label: string;
  imgType?: '1' | '2' | '3' | '4' | '5';
};

const MobileSubBanner: React.FC<Props> = ({ label, imgType = '1' }) => {
  return (
    <Root>
      {imgType === '1' && <BannerImg src={sub_banner} />}
      {imgType === '2' && <BannerImg src={sub_banner_2} />}
      {imgType === '3' && <BannerImg src={sub_banner_3} />}
      {imgType === '4' && <BannerImg src={sub_banner_4} />}
      {imgType === '5' && <BannerImg src={sub_banner_5} />}
      <TitleTypo>{label}</TitleTypo>
    </Root>
  );
};

export default MobileSubBanner;
