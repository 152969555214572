import React, { useEffect, useState } from 'react';
import Header from 'systems/Header';
import {
  Container,
  ContentContainer,
  ContentHeaderChip,
  ContentHeaderContainer,
  ContentHeaderInfoContainer,
  ContentHeaderResponseChip,
  ContentHeaderRoot,
  ContentHeaderTypo,
  ContentResponseImg,
  Root,
  SearchBarButton,
  SearchBarContainer,
  SearchBarInput,
  SearchBarSelect,
} from './styled';
import SubBanner from 'systems/SubBanner';
import NoticeNavigator from 'systems/NoticeNavigator';
import { commonAxios } from 'api/commonAxios';
import responseImg from 'assets/images/response.png';
import moment from 'moment';
import { isMobile } from 'react-device-detect';
import MobileMyQuestion from './Mobile';
import NoticeSearchBar from 'systems/NoticeSearchBar';
import Footer from 'systems/Footer';

type NoticeType = {
  title: string;
  content: string;
  created_at: string;
  answer: {
    title: string;
    content: string;
    created_at: string;
  };
};

const MyQuestion = () => {
  const [dataList, setDataList] = useState<NoticeType[]>();
  let accessToken = localStorage.getItem('access_token');

  useEffect(() => {
    if (accessToken) {
      commonAxios({
        url: 'questions/',
        method: 'GET',
        Authorization: `Bearer ${accessToken}`,
      }).then((res) => {
        setDataList(res.data);
      });
    }
  }, []);

  if (isMobile) {
    return <MobileMyQuestion />;
  }

  return (
    <Root>
      <Header />
      <SubBanner label='공지사항' imgType='3' />
      <NoticeNavigator type='4' />
      <Container>
        <NoticeSearchBar />
        <ContentContainer>
          {dataList?.map((value, index) => {
            return (
              <>
                {' '}
                <ContentHeaderRoot>
                  <ContentHeaderContainer>
                    <ContentHeaderInfoContainer>
                      <ContentHeaderChip>질문</ContentHeaderChip>
                      <ContentHeaderTypo>{value.title}</ContentHeaderTypo>
                    </ContentHeaderInfoContainer>
                    <ContentHeaderTypo>
                      {moment(value.created_at).format('YYYY.MM.DD')}
                    </ContentHeaderTypo>
                  </ContentHeaderContainer>
                  <ContentHeaderTypo
                    style={{ marginLeft: 20, marginBottom: 10 }}
                  >
                    {value.content}
                  </ContentHeaderTypo>
                </ContentHeaderRoot>
                {value.answer && (
                  <ContentHeaderRoot>
                    <ContentHeaderContainer>
                      <ContentHeaderInfoContainer>
                        <ContentResponseImg src={responseImg} />
                        <ContentHeaderResponseChip>
                          답변
                        </ContentHeaderResponseChip>
                        <ContentHeaderTypo>
                          {value.answer.title}
                        </ContentHeaderTypo>
                      </ContentHeaderInfoContainer>
                      <ContentHeaderTypo>
                        {moment(value.answer.created_at).format('YYYY.MM.DD')}
                      </ContentHeaderTypo>
                    </ContentHeaderContainer>
                    <ContentHeaderTypo
                      style={{ marginLeft: 50, marginBottom: 10 }}
                    >
                      {value.answer.content}
                    </ContentHeaderTypo>
                  </ContentHeaderRoot>
                )}
              </>
            );
          })}
        </ContentContainer>
      </Container>
      <Footer />
    </Root>
  );
};

export default MyQuestion;
