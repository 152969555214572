import { Modal } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import {
  Bar,
  Chip,
  ChipWrapper,
  Line,
  NodeContainer,
  Root,
  SubLine,
} from './styled';

type Props = {
  visible: boolean;
  onCancel: () => void;
  parent: any;
  me: any;
  childrenList: any[];
  myId: any;
};

const ParentModal: React.FC<Props> = ({
  visible,
  onCancel,
  parent,
  me,
  childrenList,
  myId,
}) => {
  const divRef = useRef<any>();
  const [divWidth, setDivWidth] = useState<number>(0);

  const width = (() => {
    let a = 0;
    let b = 0;
    childrenList &&
      childrenList.length > 0 &&
      childrenList
        .filter((value2) => myId === value2.parent)
        .forEach((value, index) => {
          if (index === 0) {
            a = childrenList.filter(
              (value2) => value.id === value2.parent
            ).length;
          }
          b = childrenList.filter(
            (value2) => value.id === value2.parent
          ).length;
        });

    if (a === 0) {
      a = 30;
    } else if (a === 1) {
      a = 30;
    } else if (a >= 2) {
      a = 35 * a - 5;
    }

    if (b === 0) {
      b = 30;
    } else if (b === 1) {
      b = 30;
    } else if (b >= 2) {
      b = 35 * b - 5;
    }
    return { a, b };
  })();

  useEffect(() => {
    if (divRef?.current) {
      setDivWidth(divRef?.current.clientWidth);
    }
  }, [divRef?.current]);

  return (
    <Modal
      footer={null}
      visible={visible}
      title='계보도'
      onCancel={onCancel}
      width={1280}
    >
      <Root>
        {parent && (
          <NodeContainer style={{ justifyContent: 'center' }}>
            <ChipWrapper>
              <Chip>{parent.realname}</Chip>
              <Bar />
              <Bar />
            </ChipWrapper>
          </NodeContainer>
        )}
        <NodeContainer style={{ justifyContent: 'center' }}>
          <ChipWrapper>
            <Chip> {me && me.name}(나) </Chip>
          </ChipWrapper>
        </NodeContainer>
        {childrenList?.length > 0 && (
          <NodeContainer style={{ justifyContent: 'center' }}>
            <ChipWrapper style={{ width: '100%' }}>
              <Bar />
            </ChipWrapper>
          </NodeContainer>
        )}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            overflowX: 'auto',
          }}
        >
          <div
            ref={divRef}
            style={{
              display: 'flex',
              flexDirection: 'column',
              paddingLeft: divWidth > 300 ? divWidth - 300 : 0,
            }}
          >
            {childrenList?.length > 0 && (
              <>
                <Line a={width.a} b={width.b} />
                <NodeContainer
                  style={{
                    overflow: 'auto',
                  }}
                >
                  {childrenList
                    .filter((value) => myId === value.parent)
                    .map((value) => (
                      <ChipWrapper>
                        <Bar /> <Chip>{value.realname}</Chip>{' '}
                        {childrenList.filter(
                          (value2) => value.id === value2.parent
                        ).length > 0 && (
                          <>
                            <NodeContainer
                              style={{
                                justifyContent: 'center',
                              }}
                            >
                              <ChipWrapper style={{ width: '100%' }}>
                                <Bar />
                                <SubLine />
                              </ChipWrapper>
                            </NodeContainer>
                            <NodeContainer>
                              {childrenList
                                .filter((value2) => value.id === value2.parent)
                                .map((value2) => (
                                  <>
                                    <ChipWrapper>
                                      <Bar /> <Chip>{value2.realname}</Chip>{' '}
                                    </ChipWrapper>
                                  </>
                                ))}
                            </NodeContainer>
                          </>
                        )}
                      </ChipWrapper>
                    ))}
                </NodeContainer>
              </>
            )}
          </div>
        </div>
      </Root>
    </Modal>
  );
};

export default ParentModal;
