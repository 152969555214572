import React from 'react';
import { ContentImg, ContentImgWrapper, Root } from './styled';
import intro_content from 'assets/images/mobile_loan_content.png';
import MobileHeader from 'systems/Header/Mobile';
import MobileSubBanner from 'systems/SubBanner/Mobile';
import MobileFooter from 'systems/Footer/Mobile';

const MobileLoan = () => {
  return (
    <Root>
      <MobileHeader />
      <MobileSubBanner imgType='2' label='대여금' />
      <ContentImgWrapper>
        <ContentImg src={intro_content} />
      </ContentImgWrapper>
      <MobileFooter />
    </Root>
  );
};

export default MobileLoan;
