import { Button, Checkbox, Input, Select, Typography } from 'antd';
import styled from 'styled-components';

export const Root = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-top: 150px;
`;

export const Container = styled.div`
  width: 1920px;
  height: 1635px;
  background: #fafafa;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ContentContainer = styled.div`
  width: 472px;
  background: #fff;
  display: flex;
  flex-direction: column;
  padding: 50px 60px;
  box-sizing: border-box;
  border: 1px #d9d9d9 solid;
  margin-top: 80px;
`;

export const ContentTitleTypo = styled(Typography)`
  &&& {
    font-size: 28px;
    font-weight: bold;
    color: #333;
  }
`;

export const ContentIdContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 40px;
`;

export const ContentIdLabelTypo = styled(Typography)`
  &&& {
    font-size: 16px;
    font-weight: bold;
    color: #333;
  }
`;

export const ContentIdInputContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const ContentIdInput = styled(Input)`
  &&& {
    height: 40px;
    padding-left: 26px;
  }
`;

export const ContentIdButton = styled(Button)`
  &&& {
    width: 91px;
    height: 40px;
    background: #5135a4;
    border: 0;
    border-radius: 2;
  }
`;

export const ContentIdButtonTypo = styled(Typography)`
  &&& {
    font-size: 14px;
    font-weight: 500;
    color: white;
  }
`;

export const ContentPasswordContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 24px;
`;

export const ContentPasswordLabelTypo = styled(Typography)`
  &&& {
    font-size: 16px;
    font-weight: bold;
    color: #333;
  }
`;

export const ContentPasswordInputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 7px;
`;

export const ContentPasswordInput = styled(Input)`
  &&& {
    height: 40px;
    padding-left: 26px;
  }
`;

export const ContentNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 24px;
`;

export const ContentNameLabelTypo = styled(Typography)`
  &&& {
    font-size: 16px;
    font-weight: bold;
    color: #333;
  }
`;

export const ContentNameInput = styled(Input)`
  &&& {
    height: 40px;
    padding-left: 26px;
  }
`;

export const ContentRecommendContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 24px;
`;

export const ContentRecommendLabelTypo = styled(Typography)`
  &&& {
    font-size: 16px;
    font-weight: bold;
    color: #333;
  }
`;

export const ContentRecommendInput = styled(Input)`
  &&& {
    height: 40px;
    padding-left: 26px;
  }
`;

export const ContentInfoContainer = styled.div`
  display: flex;
  gap: 35px;
  margin-top: 24px;
`;

export const ContentInfoGenderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const ContentInfoGenderLabelTypo = styled(Typography)`
  &&& {
    font-size: 16px;
    font-weight: bold;
    color: #333;
  }
`;

export const ContentInfoGenderSwitchContainer = styled.div`
  width: 157px;
  height: 40px;
  display: flex;
  align-items: center;
`;

type ContentInfoGenderSwitchButtonProps = {
  isPointed: boolean;
};

export const ContentInfoGenderSwitchButton = styled(
  Button
)<ContentInfoGenderSwitchButtonProps>`
  &&& {
    width: 79px;
    height: 40px;
    border-radius: 0;
    ${(props) =>
      props.isPointed
        ? `
      color: white;
      background: #5135A4;
      border: 1px 5135A4 solid;
    `
        : `
    border: 1px D9D9D9 solid;
    `}
  }
`;

export const ContentInfoNationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const ContentInfoNationLabelTypo = styled(Typography)`
  &&& {
    font-size: 16px;
    font-weight: bold;
    color: #333;
  }
`;

export const ContentInfoNationSwitchContainer = styled.div`
  width: 157px;
  height: 40px;
  display: flex;
  align-items: center;
`;

type ContentInfoNationSwitchButtonProps = {
  isPointed: boolean;
};

export const ContentInfoNationSwitchButton = styled(
  Button
)<ContentInfoNationSwitchButtonProps>`
  &&& {
    width: 79px;
    height: 40px;
    border-radius: 0;
    ${(props) =>
      props.isPointed
        ? `
      color: white;
      background: #5135A4;
      border: 1px 5135A4 solid;
    `
        : `
    border: 1px D9D9D9 solid;
    `}
  }
`;

export const ContentBirthContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 24px;
`;

export const ContentBirthLabelTypo = styled(Typography)`
  &&& {
    font-size: 16px;
    font-weight: bold;
    color: #333;
  }
`;

export const ContentBirthInput = styled(Input)`
  &&& {
    height: 40px;
    padding-left: 26px;
  }
`;

export const ContentAccountContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 24px;
`;

export const ContentAccountLabelTypo = styled(Typography)`
  &&& {
    font-size: 16px;
    font-weight: bold;
    color: #333;
  }
`;

export const ContentAccountInputContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const ContentAccountSelect = styled(Select)``;

export const ContentAccountInput = styled(Input)`
  &&& {
    height: 40px;
    padding-left: 26px;
  }
`;

export const ContentEmailContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 24px;
`;

export const ContentEmailLabelTypo = styled(Typography)`
  &&& {
    font-size: 16px;
    font-weight: bold;
    color: #333;
  }
`;

export const ContentEmailInput = styled(Input)`
  &&& {
    height: 40px;
    padding-left: 26px;
  }
`;

export const ContentAddressContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 24px;
`;

export const ContentAddressLabelTypo = styled(Typography)`
  &&& {
    font-size: 16px;
    font-weight: bold;
    color: #333;
  }
`;

export const ContentAddressInputLineContainer = styled.div`
  &&& {
    width: 100%;
    display: flex;
    gap: 10px;
  }
`;

export const ContentAddressInputContainer = styled.div`
  &&& {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 7px;
  }
`;

export const ContentAddressInput = styled(Input)`
  &&& {
    height: 40px;
    padding-left: 26px;
  }
`;

export const ContentAddressButton = styled(Button)`
  &&& {
    width: 108px;
    height: 40px;
    background: #5135a4;
    font-family: Pretendard;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
  }
`;

export const ContentEtcContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 24px;
`;

export const ContentEtcLabelTypo = styled(Typography)`
  &&& {
    font-size: 16px;
    font-weight: bold;
    color: #333;
  }
`;

export const ContentEtcInput = styled(Input)`
  &&& {
    height: 40px;
    padding-left: 26px;
  }
`;

export const ContentAgreeContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 15px;
`;

export const ContentAgreeCheckbox = styled(Checkbox)``;

export const ContentAgreeTypo = styled(Typography)`
  &&& {
    font-size: 14px;
    font-weight: 500;
    color: #333;
    cursor: pointer;
  }
`;

export const ContentSubmitButton = styled(Button)`
  &&& {
    width: 100%;
    height: 50px;
    background: #5135a4;
    border: 0;
    border-radius: 0;
    margin-top: 50px;
  }
`;

export const ContentSubmitButtonTypo = styled(Typography)`
  &&& {
    font-size: 16px;
    font-weight: #fff;
    color: white;
  }
`;
